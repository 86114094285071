import { Box, Center, Heading, Image, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HomeCostChart } from "../components/charts/HomeCostChart";
import { HomeProfitChart } from "../components/charts/HomeProfitChart";
import { HomeRevenueChart } from "../components/charts/HomeRevenueChart";
import { HomeTopicChart } from "../components/charts/HomeTopicChart";
import { Hero2 } from "../components/sections/HomeHero";
import { Loader } from "../components/ui/Loader";
import { ScrollUp } from "../components/ui/ScrollUp";
//import { apiUrls } from "../configs/config";
import { PageContext } from "../data/PageContext";
import IconF1 from "../data/svgs/IconF1.svg";
import IconF5 from "../data/svgs/IconF5.svg";
// import { Token } from "../types/tokens";
import { getMonth, spacing, getMaxYear } from "../utils/utils";
interface HomeProps {
  // token: Token;
  data: any;
  current: number;
}

type PageText = {
  name: string;
  headerBody: string;
  order: number;
  reportName: string;
  tag: string;
};

export const Home: React.FC<HomeProps> = ({ data, current }) => {
  const [yearSelected, setYearSelected] = useState(
    new Date().getFullYear() - 1
  );
  const { setPages } = useContext(PageContext);
  const [loading, setLoading] = useState(true);
  const [revKPI, setRevKPI] = useState("Vantar upplýsingar");
  const [costKPI, setCostKPI] = useState("Vantar upplýsingar");
  const location = useLocation();
  const [graphData, setGraphData] = useState<any>(data);
  // eslint-disable-next-line
  const [maxMonth] = useState({
    num: current,
    name: getMonth(current),
  });

  const [pageText] = useState<PageText>();
  const currentYear = getMaxYear();
  // const currentYear = new Date().getFullYear();

  useEffect(() => {
    // Check if current page is same as location
    if (location.pathname === "/") {
      setPages!((pages) => {
        return { ...pages, page: 0 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const filter1: any = data.graph1.filter((objdata: any) => {
        return objdata.year === yearSelected;
      });

      const filter2: any = data.graph2.filter((objdata: any) => {
        return objdata.year === yearSelected;
      });

      const filter3: any = data.graph3.filter((objdata: any) => {
        return objdata.year > 2016 && objdata.year < currentYear;
      });

      const filter4: any = data.graph4.filter((objdata: any) => {
        let check = yearSelected;
        if (yearSelected === 2024) check = 2024;
        return objdata.year === check;
      });

      data.graph3.forEach((item) => {
        if (parseInt(item.year) === yearSelected) {
          setRevKPI(
            Math.round(Math.abs(item.Tekjur) / 1000000000) + " milljarðar"
          );
          setCostKPI(Math.round(item.Gjöld / 1000000000) + " milljarðar");
        }
      });

      setGraphData({
        ...graphData,
        graph1: filter1.sort((a, b) => (a.value < b.value ? 1 : -1)),
        graph2: filter2.sort((a, b) => (a.value < b.value ? 1 : -1)),
        graph3: filter3.sort((a, b) => (a.year > b.year ? 1 : -1)),
        graph4: filter4[0].data
          .sort((a, b) => (a.Kostnaður < b.Kostnaður ? 1 : -1))
          .slice(0, 5),
      });

      // const client = contentful.createClient({
      //   // This is the space ID. A space is like a project folder in Contentful terms
      //   space: "9b2zwy9oatgb",
      //   // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
      //   accessToken: "dSvUqwRnjsBttSuuwpWjzVsnRs3_9rjhGSUU7uPWSeM",
      // });

      // client.getEntries().then((response) => {
      //   const items = response.items[0];
      //   const fields = items.fields as PageText;
      //   console.log("ff", fields);
      //   setPageText(fields);
      // });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearSelected]);

  return (
    <>
      <Box
        display={["block", "block", "block", "none"]}
        bgColor="red.100"
        px={20}
      >
        <Text fontSize="16px">
          Vefurinn er hannaður fyrir stærri skjá. Sum gröf og töflur er ekki
          hægt að birta í vafra í síma eða spjaldtölvu.
        </Text>
      </Box>
      <Box pos="relative" w={spacing.WIDE}>
        <Hero2
          pageHeader="Ríkisreikningur"
          headerText={[
            "Á ríkisreikningur.is má finna ýmsan fróðleik um rekstur og mannauð ríkisins.",
          ]}
          headerAsset=""
          yearSelected={yearSelected}
          setYearSelected={setYearSelected}
          includeYearFilter={true}
          includeKPI={true}
          includeSearch={false}
          revenueKPI={revKPI}
          costKPI={costKPI}
        />
        <Box
          pos="absolute"
          top={[10, 20, 5, 5, 5, 0]}
          right={[0, 0, 5, 5, 5, 10]}
        >
          <Image
            h={["100px", "200px", "250px", "300px", "350px", "500px"]}
            src={IconF1}
            alt="Icon"
            display={["none", "none", "none", "block"]}
          />
        </Box>
        <ScrollUp />
      </Box>
      <Box align="center" w={spacing.WIDE} mt={8}>
        {loading ? (
          <Box my={12}>
            <Heading>Hleð inn gögn...</Heading>
            <Loader />
          </Box>
        ) : (
          <>
            <Box my={[2, 2, 4, 8]} w={spacing.FULL}>
              <HomeRevenueChart
                data={graphData.graph1}
                graphTitle={"Tekjur árið " + yearSelected}
                year={yearSelected}
                setYear={setYearSelected}
                header={pageText?.name}
                body={pageText?.headerBody}
              />
              <HomeCostChart
                data={graphData.graph2}
                graphTitle={"Gjöld árið " + yearSelected}
                year={yearSelected}
                setYear={setYearSelected}
              />
              <HomeProfitChart
                data={graphData.graph3}
                graphTitle="Afkoma árin "
              />
              <Center w="100%">
                <img src={IconF5} alt="Icon" />
              </Center>
              <HomeTopicChart
                data={graphData.graph4}
                graphTitle={"5 gjaldahæstu málefnasviðin árið " + yearSelected}
                year={yearSelected}
                setYear={setYearSelected}
              />
              {yearSelected === currentYear ? ( //new Date().getFullYear() ? (
                <Center
                  position="fixed"
                  p={4}
                  bottom={5}
                  left={0}
                  right={0}
                  margin="auto"
                  fontSize="xl"
                  bgColor="green.100"
                  fontWeight="black"
                  border="2px solid #808080"
                  borderRadius="10px"
                  maxW="400px"
                  align="left"
                  w="100%"
                >
                  Árið {yearSelected} sýnir gögn til og með {maxMonth.name}.
                </Center>
              ) : null}
            </Box>
          </>

          // <HomeSection
          //   data={graphData}
          //   yearSelected={yearSelected}
          //   setYearSelected={setYearSelected}
          // />
        )}
      </Box>
    </>
  );
};
