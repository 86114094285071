import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface HeadingDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  pages: {
    page: number;
    reportPage: number;
    hrPage: number;
    reikPage: number;
  };
  setPages:
    | React.Dispatch<
        React.SetStateAction<{
          page: number;
          reportPage: number;
          hrPage: number;
          reikPage: number;
        }>
      >
    | undefined;
}

export const HeaderDrawer: React.FC<HeadingDrawerProps> = ({
  isOpen,
  onClose,
  pages,
  setPages,
}) => {
  return (
    <Drawer isOpen={isOpen} size="full" placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Valmynd</DrawerHeader>

        <DrawerBody>
          <Flex
            align={["center", "center", "center", "center"]}
            justify={["center", "space-between", "flex-end", "flex-end"]}
            direction={["column", "column", "row", "row"]}
            h="100%"
            textTransform="uppercase"
          >
            <Center
              minH="40px"
              px={4}
              borderTop={
                pages.page === 0 ? "5px solid #003D85" : "5px solid #ffffff"
              }
              onClick={() => setPages!({ ...pages, page: 0 })}
            >
              <Link to="/">
                <Box h="100%" textAlign="center" onClick={onClose}>
                  Forsíða
                </Box>
              </Link>
            </Center>
            {/* <Center
              minH="40px"
              px={4}
              borderTop={
                pages.page === 1 ? "5px solid #003D85" : "5px solid #ffffff"
              }
              onClick={() => setPages!({ ...pages, page: 1 })}
            >
              <Link to="/fjarhagur">
                <Box h="100%" textAlign="center">
                  Fjárhagsgögn
                </Box>
              </Link>
            </Center>
            <Center
              minH="40px"
              px={4}
              borderTop={
                pages.page === 2 ? "5px solid #003D85" : "5px solid #ffffff"
              }
              onClick={() => setPages!({ ...pages, page: 2 })}
            >
              <Link to="/mannaudur">
                <Box h="100%" textAlign="center">
                  Mannauðsgögn
                </Box>
              </Link>
            </Center>
            <Center
              minH="40px"
              px={4}
              borderTop={
                pages.page === 3 ? "5px solid #003D85" : "5px solid #ffffff"
              }
              onClick={() => setPages!({ ...pages, page: 3 })}
            >
              <Link to="/reikningur">
                <Box h="100%" textAlign="center">
                  Efnahags- og rekstrarreikningur
                </Box>
              </Link>
            </Center>
            <Center
              minH="40px%"
              px={4}
              borderTop={
                pages.page === 4 ? "5px solid #003D85" : "5px solid #ffffff"
              }
              onClick={() => setPages!({ ...pages, page: 4 })}
            >
              <Link to="/utgefin-gogn">
                <Box h="100%" textAlign="center">
                  Útgefin gögn
                </Box>
              </Link>
            </Center> */}
            <Center
              minH="40px%"
              px={4}
              borderTop={
                pages.page === 5 ? "5px solid #003D85" : "5px solid #ffffff"
              }
              onClick={() => setPages!({ ...pages, page: 5 })}
            >
              <Link to="/um-siduna">
                <Box h="100%" textAlign="center" onClick={onClose}>
                  Um síðuna
                </Box>
              </Link>
            </Center>
            <Spacer />
          </Flex>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Loka valmynd
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
