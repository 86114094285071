import { Box, Heading, HStack, Spacer } from "@chakra-ui/react";
import React from "react";
import { Search } from "../Search";
import { DisclaimerButton } from "../ui/Disclaimerbutton";
import { HeadingCard } from "../ui/HeadingCard";
import { StatCardLine } from "../ui/StatCardLine";
import { YearSelecter } from "../ui/YearSelecter";

interface HeroProps {
  pageHeader: string;
  headerText: string[];
  headerAsset: string;
  yearSelected: number;
  setYearSelected: React.Dispatch<React.SetStateAction<number>>;
  includeYearFilter?: boolean;
  includeKPI?: boolean;
  includeSearch?: boolean;
  revenueKPI?: string;
  costKPI?: string;
}

const spacing = {
  FULL: ["100%", "100%", "100%", "100%"],
  WIDE: ["80%", "80%", "90%", "90%"],
};

export const Hero2: React.FC<HeroProps> = ({
  pageHeader,
  headerText,
  headerAsset,
  yearSelected,
  setYearSelected,
  includeYearFilter,
  includeKPI,
  includeSearch,
  revenueKPI,
  costKPI,
}) => {
  return (
    <>
      <Box align="center" w={spacing.FULL}>
        <Box
          my={[3, 3, 3, 3]}
          py={[3, 3, 3, 3]}
          maxW={spacing.WIDE}
          textAlign="left"
        >
          <HeadingCard heading={pageHeader} panel={headerText} size="4xl" />
          <DisclaimerButton />
        </Box>
        {includeYearFilter ? (
          <>
            <HStack
              my={[2, 3, 2, 2]}
              py={[2, 3, 2, 2]}
              maxW={["90%", "90%", "95%", "95%"]}
              textAlign="left"
            >
              <Heading
                fontSize={["50px", "100px", "150px", "200px"]}
                color="headers.blue"
                minW={["50px", "50px", "50px", "450px"]}
              >
                {yearSelected}
              </Heading>
              <YearSelecter
                yearSelected={yearSelected}
                setYearSelected={setYearSelected}
              />
            </HStack>
          </>
        ) : null}
        {includeKPI ? (
          <Box textAlign="left" maxW={["80%", "80%", "90%", "90%"]} my={8}>
            <HStack maxW={["100%", "100%", "90%", "80%", "70%", "70%"]}>
              <StatCardLine
                heading="Tekjur"
                value={revenueKPI ? revenueKPI : "Vantar upplýsingar"}
                color="#4E8ECC"
              />
              <Spacer />
              <StatCardLine
                heading="Gjöld"
                value={costKPI ? costKPI : "Vantar upplýsingar"}
                color="#C75F93"
              />
            </HStack>
          </Box>
        ) : null}
        {includeSearch ? (
          <Box
            textAlign="left"
            maxW={["80%", "80%", "80%", "85%"]}
            my={8}
            display={["none", "none", "none", "block"]}
          >
            <Heading size="sm">
              Má bjóða þér að nýta leitarmöguleikan okkar
            </Heading>
            <Search />
          </Box>
        ) : null}
      </Box>
    </>
  );
};
