import { Box, Divider, Text } from "@chakra-ui/react";
import * as React from "react";
import { concepts } from "../data/about";

export const drawerContents = (location) => {
  if (location === "/") {
    return (
      <>
        {concepts
          .filter((concept) => concept.tags.includes("home"))
          .map((section, idx) => {
            return (
              <Box key={`box-home-${idx}`}>
                <Text key={`concept-home-${idx}`} fontSize="lg">
                  {section.concept}
                </Text>
                <Text key={`text-home-${idx}`} fontSize="md">
                  {section.text}
                </Text>
                <Divider my={4} />
              </Box>
            );
          })}
      </>
    );
  } else if (location === "/fjarhagur") {
    return (
      <>
        {concepts
          .filter((concept) => concept.tags.includes("reports"))
          .map((section, idx) => {
            return (
              <Box key={`box-reports-${idx}`}>
                <Text key={`concept-reports-${idx}`} fontSize="lg">
                  {section.concept}
                </Text>
                <Text key={`text-reports-${idx}`} fontSize="md">
                  {section.text}
                </Text>
                <Divider my={4} />
              </Box>
            );
          })}
      </>
    );
  } else if (location === "/mannaudur") {
    return (
      <>
        {concepts
          .filter((concept) => concept.tags.includes("hr"))
          .map((section, idx) => {
            return (
              <Box key={`box-hr-${idx}`}>
                <Text key={`concept-hr-${idx}`} fontSize="lg">
                  {section.concept}
                </Text>
                <Text key={`text-hr-${idx}`} fontSize="md">
                  {section.text}
                </Text>
                <Divider my={4} />
              </Box>
            );
          })}
      </>
    );
  } else if (location === "/reikningur") {
    return (
      <>
        {concepts
          .filter((concept) => concept.tags.includes("reikningur"))
          .map((section, idx) => {
            return (
              <Box key={`box-reik-${idx}`}>
                <Text key={`concept-reik-${idx}`} fontSize="lg">
                  {section.concept}
                </Text>
                <Text key={`text-reik-${idx}`} fontSize="md">
                  {section.text}
                </Text>
                <Divider my={4} />
              </Box>
            );
          })}
      </>
    );
  }
};
