import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ReactComponent as DataIcon } from "../data/data.svg";
import { ReactComponent as DocIcon } from "../data/document.svg";
// import { searchData } from "../data/searchData";

interface SearchProps {}

export const Search: React.FC<SearchProps> = () => {
  const [search, setSearch] = useState("");
  const [state, setState] = useState({ searchActive: false, searchLength: 0 });
  // const [data] = useState<any>(searchData);
  const [results, setResults] = useState<any>([]);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearch(e.currentTarget?.value);
    setState({
      ...state,
      searchLength: e.currentTarget?.value.length,
    });
    if (e.currentTarget?.value.length > 2) {
      // Current search value length > 2 and the searchActive state is not currently true.
      if (!state.searchActive) {
        setState({ ...state, searchActive: true });
      }
      // Perform the search
      let matches = new Set();

      setResults(Array.from(matches));
    } else if (e.currentTarget?.value.length <= 2) {
      if (state.searchActive) {
        setState({ ...state, searchActive: false });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Enter key pressed return the search term.
      setSearch("");
    }
    if (e.key === "Escape") {
      setState({ ...state, searchActive: false });
      setResults([]);
      setSearch("");
    }
  };

  return (
    <>
      <Box pos="relative" my={4}>
        <Flex bgColor="white">
          <InputGroup size="lg">
            <InputLeftElement
              pointerEvents="none"
              children={<SearchIcon color="headers.gray" />}
            />
            <Input
              id="search"
              type="text"
              name="search"
              //onChange={props.handleSearch}
              //value={props.searchField}
              value={search}
              placeholder="Leita..."
              boxShadow="0px 30px 60px rgba(0, 0, 0, 0.15)"
              borderRadius="10px"
              border="1px solid #D9D9D9"
              onChange={handleSearch}
              onKeyDown={handleKeyDown}
              icon
            />
            <InputRightElement w="10rem">
              <Button variant="outline" size="md" m={4} onClick={handleClick}>
                {show ? "Loka" : "Áfram"}
              </Button>
            </InputRightElement>
          </InputGroup>

          {/* <Button ml="-10px" w={200} colorScheme="blue" onClick={handleClick}>
            Leita
          </Button> */}
        </Flex>
      </Box>

      {state.searchActive ? (
        <Box
          mt={1}
          p={2}
          boxShadow="0px 30px 60px rgba(0, 0, 0, 0.15)"
          borderRadius="10px"
          border="1px solid #D9D9D9"
          bgColor="white"
          width="85%"
          minH="100px"
          pos="absolute"
          zIndex="1"
        >
          <VStack spacing={1} align="stretch">
            {results.map((item, idx) => {
              return (
                <Link key={idx} href={item.link}>
                  <HStack
                    bgColor={idx % 2 === 0 ? "white" : "gray.100"}
                    p={4}
                    spacing="20px"
                  >
                    <Box h="30px" w="30px">
                      {item.type === "data" ? <DataIcon /> : <DocIcon />}
                    </Box>
                    <Box>
                      <Text fontSize="lg">
                        <Text fontWeight="bold" display="inline">
                          {item.page}
                        </Text>{" "}
                        {" - " + item.description}
                      </Text>
                    </Box>
                  </HStack>
                </Link>
              );
            })}
          </VStack>
        </Box>
      ) : null}
    </>
  );
};
