import { Box, Center, Divider, Heading, Image } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { PBIReport } from "../components/reports/PBIReport";
import { Hero2 } from "../components/sections/HomeHero";
import { Breadcrumbs } from "../components/ui/Breadcrumbs";
import { ExpandDrawer } from "../components/ui/ExpandDrawerButton";
import { PowerBiDownload } from "../components/ui/PowerBiDownload";
import { ScrollUp } from "../components/ui/ScrollUp";
import { Submenu } from "../components/ui/Submenu";
import { PageContext } from "../data/PageContext";
import { HRdata } from "../data/reports";
import Icon21 from "../data/svgs/Icon21.svg";
import Icon22 from "../data/svgs/Icon22.svg";
import Icon23 from "../data/svgs/Icon23.svg";
import Icon31 from "../data/svgs/Icon31.svg";

// import { Token } from "../types/tokens";

interface HRProps {}

const paths = ["Mannauðsgögn"];
const url = "/mannaudur";

const svgs = [Icon21, Icon22, Icon23, Icon31];

export const HR: React.FC<HRProps> = () => {
  const { pages, setPages } = useContext(PageContext);
  const [repSection, setRepSection] = useState(
    "ReportSection49c2e49214eed83451f5"
  );
  const [yearSelected, setYearSelected] = useState(2020);
  const [update, setUpdate] = useState(true);

  const changePage = (pageIdx: number) => {
    if (pages.hrPage === pageIdx) return null;
    setPages!({ ...pages, hrPage: pageIdx });
    setRepSection(HRdata.pages[pageIdx].sections[0].reportNames[0]);
    setUpdate(!update);
  };

  useEffect(() => {
    if (pages.page !== 2) {
      setPages!((pages) => {
        return { ...pages, page: 2 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [repSection, update]);

  return (
    <>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Breadcrumbs paths={paths} url={url} />
      </Box>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Submenu tabs={HRdata.pages} changePage={changePage} />
      </Box>

      <Box pos="relative" w={["90%", "90%", "90%", "90%"]}>
        <Hero2
          pageHeader={HRdata.pages[pages.hrPage].sections[0].header}
          headerText={HRdata.pages[pages.hrPage].sections[0].text}
          headerAsset=""
          yearSelected={yearSelected}
          setYearSelected={setYearSelected}
          includeYearFilter={false}
          includeKPI={false}
          includeSearch={false}
        />
        <Box
          pos="absolute"
          top={[10, 20, 5, 100, 100, 5]}
          right={[0, 0, 0, 0, 0, 0]}
        >
          <Image
            h={["100px", "200px", "250px", "200px", "300px", "300px"]}
            src={svgs[pages.hrPage]}
            alt="Icon"
            display={["none", "none", "none", "block"]}
          />
        </Box>
      </Box>
      <ScrollUp />
      <Box w={["90%", "90%", "80%", "80%"]} textAlign="left">
        <Box w={["200px"]} my={8}>
          <ExpandDrawer
            type="info"
            heading="Skýringar"
            subheading=""
          ></ExpandDrawer>
        </Box>
      </Box>
      <Box w={["90%", "90%", "80%", "80%"]} textAlign="left" mb={8}>
        <Divider my={8} />
        <Heading size="lg" color="headers.gray">
          Greining
        </Heading>
        <PowerBiDownload />
      </Box>
      <Box>
        {true ? null : (
          <>
            <PBIReport
              reportSection={
                HRdata.pages[pages.hrPage].sections[0].reportNames[0]
              }
            />
          </>
        )}
      </Box>
      <Center w="100%" h="1080px">
        <iframe
          title={HRdata.pages[pages.hrPage].sections[0].header}
          width="100%"
          height="1080px"
          src={
            "https://fjs.data.is/account/public-report?id=bada721d-e81e-4cca-b3cd-9e69617e98a4&reportPageName=" +
            repSection
          }
        ></iframe>
      </Center>
      <Divider m={12} />
    </>
  );
};
