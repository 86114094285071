import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Charts } from "../../data/reports";
import IconF6 from "../../data/svgs/IconF6.svg";
import { dataLabel } from "../../utils/charts";
import { spacing } from "../../utils/utils";
import { DownloadExcel } from "../ui/DownloadExcel";
import { ExpandDrawer } from "../ui/ExpandDrawerButton";
import { HeadingCard } from "../ui/HeadingCard";
import { TopicLegend } from "./TopicLegend";

interface HomeTopicChartProps {
  data: {
    Málefnaflokkur: string;
    Kostnaður: number;
    category: string;
  }[];
  graphTitle: string;
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
}

const COLORS = ["#4E8ECC", "#60986E", "#F2C94C", "#B5B5B5", "#F18921"];

export const HomeTopicChart: React.FC<HomeTopicChartProps> = ({
  data,
  graphTitle,
  year,
  setYear,
}) => {
  const handleClick = (item, idx) => {
    //history.push("/fjarhagur");
    let page: string = "";
    switch (idx) {
      case 0:
        page = "/fjarhagur?page=1";
        break;
      case 1:
        page = "/fjarhagur?page=1";
        break;
      case 2:
        page = "/fjarhagur?page=1";
        break;
      case 3:
        page = "/fjarhagur";
        break;
    }

    window.open(
      page,
      "_blank" // <- opens the link in a new tab or window.
    );
  };

  const bp = useBreakpointValue({ base: 0, xl: 1 });

  return (
    <Box mb={8}>
      <Box
        my={[3, 3, 3, 3]}
        py={[3, 3, 3, 3]}
        maxW={spacing.WIDE}
        textAlign="left"
      >
        <Box pos="relative">
          <HeadingCard
            heading={Charts.pages[0].sections[3].header}
            panel={Charts.pages[0].sections[3].text}
          />
          <Box
            pos="absolute"
            top={[10, 20, 5, 100, 100, 5]}
            right={[0, 0, 0, 0, 0, 0]}
          >
            <Image
              h={["100px", "100px", "150px", "200px", "300px", "300px"]}
              src={IconF6}
              alt="Icon"
              display={["none", "none", "none", "block"]}
            />
          </Box>
        </Box>
        <DownloadExcel url="test" />
        <Box w={["200px"]} my={8}>
          <ExpandDrawer
            type="info"
            heading="Skýringar"
            subheading=""
          ></ExpandDrawer>
        </Box>
      </Box>
      {bp === 0 ? (
        <VStack w="100%" alignItems="center">
          <TopicLegend
            graphTitle={graphTitle}
            year={year}
            setYear={setYear}
            data={data}
          />
          <VStack
            w={["40%", "100%", "100%", "100%"]}
            display={["none", "block"]}
          >
            <ResponsiveContainer width="80%" height={600}>
              <BarChart
                layout="vertical"
                width={400}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 15,
                }}
                onClick={(item) => handleClick(item, 3)}
              >
                <XAxis
                  type="number"
                  tickFormatter={(value) =>
                    new Intl.NumberFormat("de-DE").format(value / 1000000000)
                  }
                >
                  <Label
                    value="Kostnaður í krónum"
                    offset={-10}
                    position="insideBottom"
                  />
                </XAxis>
                <YAxis
                  width={200}
                  type="category"
                  dataKey="Málefnaflokkur"
                  hide={true}
                />
                <Tooltip formatter={(value) => dataLabel(value)} />

                <Bar dataKey="Kostnaður" fill={COLORS[0]}>
                  <LabelList
                    dataKey="Kostnaður"
                    position="top"
                    content={(props) => {
                      const { x, y, height, width, value } = props;

                      return (
                        <text
                          x={(x as number) + (width as number) / 2}
                          y={(y as number) + (height as number) / 2}
                          fill="white"
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          {dataLabel(value)}
                        </text>
                      );
                    }}
                  />
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </VStack>
        </VStack>
      ) : (
        <HStack w={spacing.WIDE} alignItems="center">
          <Flex
            w={["40%", "40%", "40%", "40%"]}
            align="left"
            alignItems="left"
            textAlign="left"
            direction="column"
          >
            <TopicLegend
              graphTitle={graphTitle}
              year={year}
              setYear={setYear}
              data={data}
            />
          </Flex>

          <VStack w={["80%", "80%", "80%", "100%", "100%"]} mb={4}>
            <>
              <ResponsiveContainer width="99%" height={500}>
                <BarChart
                  layout="vertical"
                  width={400}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 15,
                  }}
                  onClick={(item) => handleClick(item, 3)}
                >
                  <XAxis
                    type="number"
                    tickFormatter={(value) =>
                      new Intl.NumberFormat("de-DE").format(value / 1000000000)
                    }
                  >
                    <Label
                      value="Kostnaður í milljörðum króna"
                      offset={-10}
                      position="insideBottom"
                    />
                  </XAxis>
                  <YAxis
                    width={200}
                    type="category"
                    dataKey="Málefnaflokkur"
                    hide={true}
                  />
                  <Tooltip formatter={(value) => dataLabel(value)} />

                  <Bar dataKey="Kostnaður" fill={COLORS[0]}>
                    <LabelList
                      dataKey="Kostnaður"
                      position="top"
                      content={(props) => {
                        const { x, y, height, width, value } = props;

                        return (
                          <text
                            x={(x as number) + (width as number) / 2}
                            y={(y as number) + (height as number) / 2}
                            fill="white"
                            textAnchor="middle"
                            dominantBaseline="middle"
                          >
                            {dataLabel(value)}
                          </text>
                        );
                      }}
                    />
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </>
          </VStack>
        </HStack>
      )}
      <Divider my={20} w="85%" borderBottom="2px solid #5A5E61" />
    </Box>
  );
};
