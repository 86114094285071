import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import {
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Charts } from "../../data/reports";
import IconF4 from "../../data/svgs/IconF4.svg";
import { dataLabel, formatLabel } from "../../utils/charts";
import { spacing } from "../../utils/utils";
import { DownloadExcel } from "../ui/DownloadExcel";
import { ExpandDrawer } from "../ui/ExpandDrawerButton";
import { HeadingCard } from "../ui/HeadingCard";
import { ProfitLegend } from "./ProfitLegend";

interface HomeProfitChartProps {
  data: {
    year: string;
    revenue: number;
    costs: number;
    profit: number;
  }[];
  graphTitle: string;
}

const COLORS = ["#4E8ECC", "#C75F93", "#2A3E5A", "#FF8042"];

export const HomeProfitChart: React.FC<HomeProfitChartProps> = ({
  data,
  graphTitle,
}) => {
  const handleClick = (item, idx) => {
    //history.push("/fjarhagur");
    let page: string = "";
    switch (idx) {
      case 0:
        page = "/fjarhagur?page=1";
        break;
      case 1:
        page = "/fjarhagur?page=1";
        break;
      case 2:
        page = "/fjarhagur?page=1";
        break;
      case 3:
        page = "/fjarhagur";
        break;
    }

    window.open(
      page,
      "_blank" // <- opens the link in a new tab or window.
    );
  };

  const bp = useBreakpointValue({ base: 0, xl: 1 });

  return (
    <Box mb={8}>
      <Box
        my={[3, 3, 3, 3]}
        py={[3, 3, 3, 3]}
        maxW={spacing.WIDE}
        textAlign="left"
      >
        <Box pos="relative">
          <HeadingCard
            heading={Charts.pages[0].sections[2].header}
            panel={Charts.pages[0].sections[2].text}
          />
          <Box
            pos="absolute"
            top={[10, 20, 5, 100, 100, 5]}
            right={[0, 0, 0, 0, 0, 0]}
          >
            <Image
              h={["100px", "100px", "150px", "200px", "300px", "300px"]}
              src={IconF4}
              alt="Icon"
              display={["none", "none", "none", "block"]}
            />
          </Box>
        </Box>
        <DownloadExcel url="test" />
        <Box w={["200px"]} my={8}>
          <ExpandDrawer
            type="info"
            heading="Skýringar"
            subheading=""
          ></ExpandDrawer>
        </Box>
      </Box>
      {bp === 0 ? (
        <>
          <Text fontSize="24px" display={["block", "none"]}>
            Grafið sem á að birtast hér kemur ekki vel út á litlum skjáum.
            Vinsamlegast opnið síðuna í tölvu til að sjá grafið.
          </Text>
          <VStack w="100%" alignItems="center" display={["none", "block"]}>
            <ProfitLegend graphTitle={graphTitle} data={data} />
            <VStack w={["40%", "100%", "100%", "100%"]}>
              <ResponsiveContainer width="80%" height={500}>
                <ComposedChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  onClick={(item) => handleClick(item, 2)}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis dataKey="year" label="Ár" height={50} />
                  <YAxis
                    padding={{ top: 50 }}
                    label={{
                      value: "Tekjur og kostnaður (ma.)",
                      angle: -90,
                      position: "left",
                    }}
                    tickFormatter={(value) =>
                      new Intl.NumberFormat("is").format(value / 1000000000)
                    }
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    padding={{ top: 50 }}
                    label={{
                      value: "Afkoma (ma.)",
                      angle: 90,
                      position: "right",
                    }}
                    tickFormatter={(value) => {
                      return new Intl.NumberFormat("is").format(
                        value / 1000000000
                      );
                    }}
                  />

                  <Tooltip formatter={(value) => dataLabel(value)} />
                  <Bar
                    dataKey="Tekjur"
                    fill={COLORS[0]}
                    label={formatLabel}
                  ></Bar>
                  <Bar
                    dataKey="Gjöld"
                    fill={COLORS[1]}
                    label={formatLabel}
                  ></Bar>
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Afkoma"
                    stroke={COLORS[2]}
                    strokeWidth={3}
                    dot={{ stroke: "#2A3E5A", strokeWidth: 3 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </VStack>
          </VStack>
        </>
      ) : (
        <HStack w={spacing.WIDE} alignItems="center">
          <Flex
            w={["40%", "40%", "40%", "40%"]}
            align="left"
            alignItems="left"
            textAlign="left"
            direction="column"
          >
            <ProfitLegend graphTitle={graphTitle} data={data} />
          </Flex>

          <VStack w={["80%", "80%", "80%", "100%", "100%"]} mb={4}>
            <>
              <ResponsiveContainer width="80%" height={600}>
                <ComposedChart
                  width={500}
                  height={300}
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  onClick={(item) => handleClick(item, 2)}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis dataKey="year" />
                  <YAxis
                    padding={{ top: 50 }}
                    label={{
                      value: "Tekjur og kostnaður (ma.)",
                      position: "left",
                    }}
                    tickFormatter={(value) =>
                      new Intl.NumberFormat("de-DE").format(value / 1000000000)
                    }
                  />
                  <YAxis
                    yAxisId="right"
                    orientation="right"
                    padding={{ top: 50 }}
                    label={{
                      value: "Afkoma (ma.)",
                      position: "right",
                    }}
                    tickFormatter={(value) =>
                      new Intl.NumberFormat("de-DE").format(value / 1000000000)
                    }
                  />

                  <Tooltip formatter={(value) => dataLabel(value)} />
                  <Bar
                    dataKey="Tekjur"
                    fill={COLORS[0]}
                    label={formatLabel}
                  ></Bar>
                  <Bar
                    dataKey="Gjöld"
                    fill={COLORS[1]}
                    label={formatLabel}
                  ></Bar>
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Afkoma"
                    stroke={COLORS[2]}
                    strokeWidth={3}
                    dot={{ stroke: "#2A3E5A", strokeWidth: 3 }}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </>
          </VStack>
        </HStack>
      )}
    </Box>
  );
};
