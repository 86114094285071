export const about = [
  {
    heading: "Um Heimamund",
    texti: `Fjársýslan er þjónustu- og þekkingarstofnun á sviði opinberra fjármála. Stofnunin veitir fjármálatengda þjónustu til ríkissjóðs og ríkisaðila og annast umsýslu fjármuna. Þá hefur hún yfirumsjón með bókhaldi og uppgjörum ríkissjóðs og tryggir tímanlegar og áreiðanlegar upplýsingar um fjármál ríkisins.`,
  },
];

export const concepts = [
  {
    concept: "Skattar",
    text: "Skattar eru gjöld sem lögð eru á þegna og fyrirtæki með heimild í lögum eða reglugerðum.  Sköttum er ætlað að mæta þeim sameiginlega kostnaði sem allir íbúar landsins þurfa að standa straum af til að reka þjóðfélagið.",
    tags: ["home"],
  },
  {
    concept: "Sala á vöru og þjónustu",
    text: "Hér undir falla tekjur stofnana af ýmissi þjónustu s.s. heilbrigðisþjónustu, margvíslegri sérfræðiþjónustu, vöru og verksölu.",
    tags: ["home"],
  },
  {
    concept: "Aðrar tekjur",
    text: "Undir þennan flokk falla margvíslegar tekjur s.s. framlög frá erlendum og innlendum aðilum, söluhagnaður fastafjármuna, kostnaðarhlutdeild, gjafir og  sala náttúruauðæfa og viðskiptasamninga.",
    tags: ["home"],
  },
  {
    concept: "Laun",
    text: "Undir þennan lið falla allar launagreiðslur og launatengd gjöld  en íslenska ríkið er einn stærsti launagreiðandi landsins með yfir 18.000 starfsmenn.",
    tags: ["home"],
  },
  {
    concept: "Vörukaup og þjónusta",
    text: "Undir þennan lið falla kaup ríkisins á margvíslegri vöru og þjónustu.  Stærstu liðirnir eru kaup á hjúkrunar- og endurhæfingarþjónustu, heilbrigðisþjónustu utan sjúkrahúsa, kaup á þjónustu vegna framhaldsskóla og háskóla.",
    tags: ["home"],
  },
  {
    concept: "Önnur gjöld",
    text: "Hér undir fellur fjármagnskostnaður, afskrifaðar kröfur og afskriftir.",
    tags: ["home"],
  },
  {
    concept: "Tilfærslur og framlagsfé",
    text: "Styrkur eða framlag sem innt er af hendi án skuldbindingar um beint endurgjald frá móttakanda. Tilfærslur greinast annars vegar í rekstrartilfærslur sem veittar eru til samtímanota hjá móttakanda og hins vegar fjármagnstilfærslur sem veittar eru til fjárfestinga. Dæmi um tilfærslur eru: Ellilífeyrir, tekjutrygging örorkubóta, atvinnuleysisbætur og framlag til jöfnunarsjóðs sveitarfélaga.",
    tags: ["home"],
  },
  {
    concept: "Málefnaflokkur",
    text: "Byggir á flokkun Sameinuðu þjóðanna um flokkun útgjalda eftir eðli þeirra.  Þannig er t.d. kostnaði við framhaldsskóla safnað saman óháð tegundarlykli eða flokkun ríkisaðila og verkefna.",
    tags: ["reports"],
  },
  {
    concept: "Málefnasvið",
    text: "Svið eðlisskyldra málaflokka sem skilgreint er á grunni staðals Sameinuðu þjóðanna um flokkun gjalda.",
    tags: ["reports"],
  },
  {
    concept: "Ríkisaðilar",
    text: "Aðilar sem fara með ríkisvald og þær stofnanir, sjóðir og fyrirtæki sem eru að hálfu eða meirihluta í eigu ríkisins, sbr. 50. gr. laga um opinber fjármál nr. 123, 2015. Ríkisaðilar skiptast í A-, B- og C-hluta.",
    tags: ["reports"],
  },
  {
    concept: "Lykiltölur",
    text: "Tekjur og/eða gjöld deilt með magneiningu (fjöldi landsmanna, fjöldi stöðugilda).",
    tags: ["reports"],
  },
  {
    concept: "Uppruni mannauðsgagna",
    text: "Upplýsingarnar eru unnar úr mannauðskerfi ríkisins (Orra) og ná því til þeirra stofnana sem greitt hafa laun í gegnum kerfið. Upplýsingar um fjölda starfsmanna byggja á tölum yfir þá sem fengu greidd dagvinnulaun. Stöðugildi eru reiknuð þannig að deilt er í greiddar einingar dagvinnulaunategunda með umreiknistuðli hverrar launategundar. Gögnin miða við desember hvert ár.",
    tags: ["hr"],
  },
  {
    concept: "Fjöldatölur starfsmanna og stöðugilda",
    text: "Tölur um ráðuneyti innihalda fjölda í ráðuneytinu sjálfu sem og allra stofnana sem heyra undir það ráðuneyti. Fjöldatölur og launakostnaður breytist í takt við það sem valið er á síðunni hverju sinni. Stöðugildi eru birt miðað við stöðuna í desember hvert ár, en ársverk sem birt eru í fjárhagsgögnum byggja á ársmeðaltali, því getur verið mismunur á þessum tölum.",
    tags: ["hr"],
  },
];

export const qa = [
  {
    heading: "Hvaðan koma gögnin?",
    texti: [
      `Þau gögn sem fjallað er um á þessari síðu koma frá Orra sem er fjárhags- og mannauðskerfi ríkisins og eru elstu gögnin frá árinu 2004.  Þegar fjárhagsgögn eru skoðuð þá þarf að hafa í huga að á árinu 2017 tók gildi nýr reikningsskilastaðall IPSAS (International Public Sector Accounting Standards).  Þau gögn sem birt eru ná aðeins til A-hluta ríkissjóð.`,
    ],
    image: "",
  },
  {
    heading: "Eru laun stærsti kostnaðarliðurinn?",
    texti: [
      `Í huga flestra er launakostnaður stærsti kostnaðarliður ríkisins en það er ekki svo.  Sem dæmi má skoða skiptingu gjalda fyrir árið 2019 en þá sést að laun eru um 25% af heildargjöldum.  Hlutfall launa er á bilinu 28,5% árið 2017 til 24,6% árið 2019.  Öll árin er það tilfærslur sem eru stærsti kostnaðarliðurinn.`,
    ],
    image: "/chart.png",
  },
  {
    heading: "Hvað eru tilfærslur?",
    texti: [
      `Opinbera skilgreiningin er þessi:`,
      `„Styrkur eða framlag sem innt er af hendi án skuldbindingar um beint endurgjald frá móttakanda. Tilfærslur greinast annars vegar í rekstrartilfærslur sem veittar eru til samtímanota hjá móttakanda og hins vegar fjármagnstilfærslur sem veittar eru til fjárfestinga.“`,
      `Dæmi um tilfærslur eru ellilífeyrir, tekjutrygging örorkubóta, atvinnuleysisbætur og framlag til jöfnunarsjóðs sveitarfélaga.`,
    ],
    image: "",
  },
  {
    heading: "Hvernig teljum við fjölda ríkisstarfsmanna?",
    texti: [
      `Upplýsingarnar eru unnar úr mannauðskerfi ríkisins (Orra) og ná því til þeirra stofnana sem greitt hafa laun í gegnum kerfið. Upplýsingar um fjölda starfsmanna byggja á þeim sem fengu greidd dagvinnulaun og stöðugildin eru reiknuð þannig að deilt er í greiddar einingar dagvinnulaunategunda með umreiknistuðli hverrar launategundar. Gögnin eru tekin út miðað við desember hvert ár.`,
    ],
    image: "",
  },
  {
    heading: "Hvað er ríkisaðili og hvað er stofnun?",
    texti: [
      `Í lögum um opinber fjármál er hugtakið ríkisaðili skilgreint sem: „Aðilar sem fara með ríkisvald og þær stofnanir, sjóðir og fyrirtæki sem eru að hálfu eða meiri hluta í eigu ríkis og sveitarfélaga.“  Gróft séð má flokka ríkisaðila í stofnanir, t.d. Háskóli Íslands og verkefni t.d. barnabætur.  Hver ríkisaðili er sjálfstæð uppgjörseining í bókhaldi ríkisins.`,
    ],
    image: "",
  },
  {
    heading: "Hvernig er meðalaunakostnaður reiknaður  í lykiltölum?",
    texti: [
      `Gera þarf skýran greinamun á meðallaunum og meðallaunakostnaði.  Munurinn þar á milli liggur í launatengdum gjöldum.`,
      `Í lykiltölum er fjöldi starfsmanna í föstu starfi settur í hlutfall við heildarlaunarkostnað.  Til heildarlaunakostnaðar teljast launatengd gjöld og því er þarna um að ræða meðallaunakostnað en ekki meðallaun.`,
      `Til að nálgast meðallaun er hægt að ganga út frá því að hlutur launatengdra gjalda af heildarlaunakostnaði er um 19,5% að jafnaði.  Þannig er hægt að margaldan reiknaða meðallaunakostnað með 0,805 til að fá meðallaun stofnunar.`,
    ],
    image: "",
  },
];
export const sources = [];
