import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { BiCopyright } from "react-icons/bi";
import { spacing } from "../../utils/utils";
import { FooterContent } from "../ui/FooterContent";

interface FooterProps {}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <Flex
      direction={["column", "column", "column", "column", "row"]}
      w={spacing.WIDE}
      minH="400px"
      pos="relative"
      bottom="0"
      left="0"
      right="0"
      p={2}
      alignItems="start"
    >
      <Box h="100%" align="center" justifyItems="center" pl={4} minW="34%">
        <Link href="/">
          <Image
            h="100%"
            src={"/09103_web_stacked_blue_foot.png"}
            display="inline"
            alt="Fjársýsla ríkisins"
          />
        </Link>
      </Box>
      <Center
        minW="20%"
        py={[4, 4, 4, 4, 0]}
        display={["none", "none", "none", "block"]}
      >
        <FooterContent header={"Frekari upplýsingar"} blue={true}>
          <VStack textAlign="left" w="100%">
            <Heading size="sm" w="100%">
              <Link href="/fjarhagur">Fjárhagsgögn</Link>
            </Heading>
            <Heading size="sm" w="100%">
              <Link href="/mannaudur">Mannauðsgögn</Link>
            </Heading>
            <Heading size="sm" w="100%">
              <Link href="/reikningur">Yfirlit yfir afkomu og efnahag</Link>
            </Heading>
            <Heading size="sm" w="100%">
              <Link href="/um-siduna">Spurt og svarað</Link>
            </Heading>
            <Heading size="sm" w="100%">
              <Link href="/um-siduna">Hugtakaskýringar</Link>
            </Heading>
            <Heading size="sm" w="100%">
              <Link href="/utgefin-gogn">Útgefin gögn</Link>
            </Heading>
          </VStack>
        </FooterContent>
      </Center>
      <Center minW="20%"></Center>
      <FooterContent header="Hafa samband" blue={true}>
        <Text w="80%">
          Allar ábendingar og athugasemdir eru vel þegnar. Endilega sendið okkur
          póst á postur@fjs.is
        </Text>
        <HStack>
          <Icon as={BiCopyright}></Icon>
          <Text>{new Date().getFullYear()} Fjársýsla ríkisins</Text>
        </HStack>
      </FooterContent>
    </Flex>
  );
};
