import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import React from "react";

interface BreadcrumbsProps {
  paths: string[];
  url: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ paths, url }) => {
  return (
    <Box my={8}>
      <Breadcrumb spacing="8px" separator={<ChevronRightIcon />}>
        <BreadcrumbItem>
          <BreadcrumbLink href="/" textTransform="uppercase">
            Forsíða
          </BreadcrumbLink>
        </BreadcrumbItem>
        {paths.length > 0
          ? paths.map((path, idx) => {
              return (
                <BreadcrumbItem key={idx}>
                  <BreadcrumbLink href={url} textTransform="uppercase">
                    {path}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              );
            })
          : null}
      </Breadcrumb>
    </Box>
  );
};
