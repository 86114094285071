import { Center } from "@chakra-ui/react";
import React from "react";
import { Loader } from "../components/ui/Loader";
// import { Token } from "../types/tokens";

interface ErrorPageProps {}

export const ErrorPage: React.FC<ErrorPageProps> = () => {
  return (
    <Center>
      <Loader error={true} />
    </Center>
  );
};
