import { Flex } from "@chakra-ui/react";
import React from "react";
import { Footer } from "../sections/Footer";
import { Header } from "../sections/Header";

interface LayoutProps {
  showSidebar?: boolean;
}

export const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <Flex
      direction="column"
      align="center"
      maxW={["100%", "100%", "100%", "100%"]}
      m="0 auto"
    >
      <Header />
      {props.children}
      <Footer />
    </Flex>
  );
};
