import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

interface HeadingCardProps {
  heading: string;
  panel: string[];
  size?: string;
}

export const HeadingCard: React.FC<HeadingCardProps> = ({
  heading,
  panel,
  size,
}) => {
  return (
    <>
      <Box>
        <Heading
          className="hero"
          py={4}
          fontSize={["30px", "48px", "64px"]}
          lineHeight={["40px", "40px", "80px"]}
          color="headers.gray"
          maxW="750px"
        >
          {heading}
        </Heading>
        <Box w={["100%", "80%", "90%", "50%", "50%", "45%"]} my={4}>
          {panel.map((text, idx) => {
            return <Text key={text + "-" + idx}>{text}</Text>;
          })}
        </Box>
      </Box>
    </>
  );
};
