import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { PageContext } from "../../data/PageContext";
import { drawerContents } from "../../utils/skyringar";

export const SideDrawer: React.FC<{}> = (props: any) => {
  const { drawer } = useContext(PageContext);

  const location = useLocation();

  const getDrawerContent = () => {
    return drawerContents(location.pathname);
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={drawer!.onClose}
      isOpen={drawer!.isOpen}
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerHeader borderBottomWidth="1px">
            Hugtök og aðrar skýringar
          </DrawerHeader>

          <DrawerBody>
            <Text fontSize="sm" fontStyle="italic" fontWeight="bold" px={8}>
              Fyrirvarar um upplýsingar á rikisreikningur.is
            </Text>
            <Text fontSize="sm" fontStyle="italic" px={8} mb={4}>
              Upplýsingar birtar á rikisreikningur.is eru samkvæmt bestu vitund
              á hverjum tíma og eru unnar beint úr fjárhags- og mannauðskerfum
              ríkisins. Í undantekningartilfellum geta þær stangast á við
              útgefnar upplýsingar sem hafa verið unnar frekar. Vakin er athygli
              á því að í ákveðnum tilvikum geta lykiltölur gefið misvísandi
              mynd, t.d. þar sem upplýsingar um starfsmannafjölda eru ekki
              tæmandi fyrir heildarlaunakostnað og svo framvegis. Fjársýsla
              ríkisins ber ekki í neinum tilvikum ábyrgð á tjóni sem kann að
              hljótast af upplýsingum er birtast á vefnum né tjóni sem rekja má
              beint eða óbeint til notkunar á vefnum. Þá ber Fjársýsla ríkisins
              ekki í neinum tilvikum ábyrgð á tjóni sem rekja má til þess að
              ekki er hægt að nota vefinn, um skemmri eða lengri tíma.
            </Text>
            <Divider my={4} />
            {getDrawerContent()}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};
