export const config = {
  authenticationMode: "ServicePrincipal",
  authorityUri: "https://login.microsoftonline.com/common/",
  scope: "https://analysis.windows.net/powerbi/api",
  apiUrl: "https://api.powerbi.com/",
  clientId: "8dc90599-8c36-40d1-9e4d-36f54daf8e8e",
  workspaceId: "223568c1-ada4-40b4-b216-8231dd50e15c",
  reportId: "65aae665-291a-4551-b01b-bd403aec9c86",
  pbiUsername: "",
  pbiPassword: "",
  clientSecret: "G2N34~~6Ay4a-H2iuuJv.ZTG5ONuUjlVDM",
  tenantId: "40265eb9-67e4-4f1b-b032-134c96651f05",
  maxYear: 2024,
};

export const apiUrls = {
  server: "http://localhost:5302",
};

export const api_key = "6d4d7394-2992-473d-9ea7-45946b39ad9d";