import { Box, Heading } from "@chakra-ui/react";
import React from "react";

interface FooterContentProps {
  header: string;
  blue?: boolean;
}

export const FooterContent: React.FC<FooterContentProps> = ({
  header,
  blue,
  children,
}) => {
  return (
    <>
      <Box w="100%" textAlign="left" my={[4, 4, 4, 4, 0]}>
        <Box
          textAlign="left"
          borderBottom={blue ? "1px solid #ffffff" : "1px solid #000000"}
          mb={4}
          py={2}
        >
          <Heading fontSize="md">{header}</Heading>
        </Box>
        {children}
      </Box>
    </>
  );
};
