import {
  Box,
  Divider,
  Heading,
  Link,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { DataSection } from "../components/sections/DataSection";
import { Breadcrumbs } from "../components/ui/Breadcrumbs";
import { HeadingCard } from "../components/ui/HeadingCard";
import { ScrollUp } from "../components/ui/ScrollUp";
import { Submenu } from "../components/ui/Submenu";
import { concepts, qa } from "../data/about";
import { PageContext } from "../data/PageContext";
import Icon51 from "../data/svgs/Icon51.svg";
import Icon52 from "../data/svgs/Icon52.svg";
import Icon53 from "../data/svgs/Icon53.svg";
import Icon54 from "../data/svgs/Icon54.svg";
import Icon55 from "../data/svgs/Icon55.svg";

interface AboutProps {}

const url = "/um-siduna";

const svgs = [Icon51, Icon52, Icon53, Icon55];

const PAGES = [
  {
    pageName: "Um síðuna",
    header: ["Um síðuna"],
    headerText: [""],
    sections: [
      {
        header: "Um síðuna",
        text: [
          "Markmið vefsins er að einfalda aðgengi almennings að fjármálum ríkisins. Ríkisreikningur sýnir endanlegt uppgjör ríkisfjármála hvers árs. Á vefnum eru helstu niðurstöður dregnar saman auk þess sem ítarlegri greiningar á ákveðnum málefnum eru aðgengilegar.",
          "Ábyrgðaraðili vefsins er Fjársýsla ríkisins, þjónustu- og þekkingarstofnun á sviði opinberra fjármála. Stofnunin veitir ríkissjóði og ríkisaðilum fjármálatengda þjónustu og umsýslu fjármuna. Þá hefur hún yfirumsjón með bókhaldi og uppgjöri ríkissjóðs og tryggir tímanlegar og áreiðanlegar upplýsingar um fjármál ríkisins.",
        ],
        reportNames: [""],
        links: [],
      },
    ],
  },
  {
    pageName: "Spurt og svarað",
    header: ["Spurt og svarað"],
    headerText: [""],
    sections: [
      {
        header: "Spurt og svarað  ",
        text: [
          "Fjármál ríkisins geta verið flókin. Það er okkar markmið að einfalda þau og gera aðgengilegri. Hér birtum við algengar spurningar og svör við þeim. Ef þú hefur spurningu um efni vefsins þá hvetjum við þig til að senda okkur línu á hafasamband@hafasamband.is.",
        ],
        reportNames: [""],
        links: [],
      },
    ],
  },
  {
    pageName: "Skýringar",
    header: ["Skýringar"],
    headerText: [""],
    sections: [
      {
        header: "Skýringar",
        text: [
          "Hér má finna helstu hugtakaskýringar og útskýringar á tölulegum gildum sem birtast á vefnum. Ef þú finnur ekki þá útskýringu sem þig vantar, þá máttu endilega senda okkur línu á hafasamband@hafasamband.is.",
        ],
        reportNames: [""],
        links: [],
      },
    ],
  },
];

const mapSection = (idx) => {
  const svgSize = svgs.length;

  if (idx === 0) {
    return <></>;
  } else if (idx === 1) {
    return (
      <>
        <Heading maxW={["80%", "80%", "60%", "75%"]} textAlign="left" py={4}>
          Algengar spurningar:
        </Heading>
        <VStack
          maxW={["80%", "80%", "60%", "75%"]}
          textAlign="left"
          alignItems="left"
        >
          {qa.map((content, idx) => {
            return (
              <Link href={"#section" + idx}>
                <Text fontSize="lg">{content.heading}</Text>
              </Link>
            );
          })}
        </VStack>
        {qa.map((section, idx) => {
          const checkIdx = idx % svgSize;
          return (
            <>
              {checkIdx % 2 === 0 ? (
                <>
                  <Box pos="relative" w="100%" my={0} id={"section" + idx}>
                    <DataSection
                      pageHeader={section.heading}
                      headerText={section.texti}
                      headerAsset=""
                    />
                    <Box
                      pos="absolute"
                      top={50}
                      right={250}
                      display={["none", "none", "none", "block"]}
                    >
                      {section.image === "" ? (
                        <img src={svgs[checkIdx]} width={300} alt="Icon" />
                      ) : (
                        <img src={section.image} width={500} alt="Icon" />
                      )}
                    </Box>
                  </Box>
                  <Divider my={12} />{" "}
                </>
              ) : (
                <>
                  <Box pos="relative" w="100%" my={0} id={"section" + idx}>
                    <Box
                      pos="absolute"
                      top={50}
                      left={250}
                      display={["none", "none", "none", "block"]}
                    >
                      {section.image === "" ? (
                        <img src={svgs[checkIdx]} width={300} alt="Icon" />
                      ) : (
                        <img src={section.image} width={500} alt="Icon" />
                      )}
                    </Box>

                    <Box align="center" w={["100%", "100%", "100%", "100%"]}>
                      <Box maxW={["80%", "80%", "60%", "75%"]} align="right">
                        <HeadingCard
                          heading={section.heading}
                          panel={section.texti}
                          size="2xl"
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Divider my={12} />{" "}
                </>
              )}
            </>
          );
        })}
      </>
    );
  } else if (idx === 2) {
    const conceptsSection = concepts.map((section, idx) => {
      return (
        <>
          <Box
            maxW={["80%", "80%", "60%", "75%"]}
            textAlign="left"
            id={"section" + idx}
          >
            <Heading fontSize="xl">{section.concept}</Heading>
            <Text fontSize="md">{section.text}</Text>
            <Divider my={4} />
          </Box>
          {idx % 5 === 0 && idx > 1 ? (
            <Box>
              <img src={Icon54} alt="Icon" />
            </Box>
          ) : null}
        </>
      );
    });

    return (
      <>
        <Heading maxW={["80%", "80%", "60%", "75%"]} textAlign="left" py={4}>
          Hugtakalisti:
        </Heading>
        <VStack
          maxW={["80%", "80%", "60%", "75%"]}
          textAlign="left"
          alignItems="left"
          mb={8}
        >
          {concepts.map((content, idx) => {
            return (
              <Link href={"#section" + idx}>
                <Text fontSize="lg">{content.concept}</Text>
              </Link>
            );
          })}
        </VStack>
        {conceptsSection}
      </>
    );
  } else return null;
};

export const About: React.FC<AboutProps> = () => {
  const { pages, setPages } = useContext(PageContext);
  const [tab, setTab] = useState(0);

  const changePage = (pageIdx: number) => {
    setTab(pageIdx);

    return null;
  };

  useEffect(() => {
    if (pages.page !== 5) {
      setPages!({ ...pages, page: 5 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Breadcrumbs paths={["Um síðuna", PAGES[tab].header[0]]} url={url} />
      </Box>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Submenu tabs={PAGES} changePage={changePage} />
      </Box>

      {tab === 0 ? (
        <Box pos="relative" w="100%" my={20}>
          <DataSection
            pageHeader={PAGES[tab].sections[0].header}
            headerText={PAGES[tab].sections[0].text}
            headerAsset=""
          />
          <Box pos="absolute" top={-20} right={100}>
            <Image
              h={["100px", "200px", "250px", "300px", "350px", "500px"]}
              src={Icon55}
              alt="Icon"
              display={["none", "none", "none", "block"]}
            />
          </Box>
        </Box>
      ) : null}
      <ScrollUp />
      <Box
        align="center"
        m="auto"
        py={["5px", "5px", "10px", "10px"]}
        w={["90%", "90%", "80%", "100%"]}
      >
        {mapSection(tab)}
      </Box>
      <Divider my={4} />
    </>
  );
};
