import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { dataLabel } from "../../utils/charts";
import { getMaxYear } from "../../utils/utils";

interface LegendProps {
  graphTitle?: string;
  year?: number;
  data: {
    name: string;
    value: number;
  }[];

  setYear: React.Dispatch<React.SetStateAction<number>>;
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export const Legend: React.FC<LegendProps> = ({
  graphTitle,
  year,
  setYear,
  data,
}) => {
  const todaysYear = getMaxYear();
  // const todaysYear = new Date().getFullYear();

  return (
    <>
      <Box>
        <Flex
          direction={["column", "column", "column", "column", "column", "row"]}
          mb={4}
          alignItems="center"
        >
          <Heading w={["100%", "100%", "100%", "70%"]} fontSize="4xl">
            Veldu ár
          </Heading>
          <VStack h="100%" w="100%" justifyContent="center">
            <Icon
              as={ChevronUpIcon}
              color={year === todaysYear ? "gray.100" : "black"}
              onClick={() => {
                year === todaysYear ? setYear(year) : setYear(year! + 1);
              }}
            />
            <Heading fontSize="4xl">{year}</Heading>
            <Icon
              as={ChevronDownIcon}
              color={year === 2017 ? "gray.100" : "black"}
              onClick={() => {
                year === 2017 ? setYear(year) : setYear(year! - 1);
              }}
            />
          </VStack>
        </Flex>

        <Box textAlign="left" borderBottom="1px solid #000000" mb={4} py={2}>
          <Heading fontSize="xl">{graphTitle}</Heading>
        </Box>
        <VStack textAlign="left" w="100%">
          {data
            ? data!.map((entry, index) => {
                return (
                  <HStack key={entry.value} maxH="50px" w="100%">
                    <Box>
                      <svg width="30" height="30" viewBox="0 0 100 100">
                        <circle
                          cx="50"
                          cy="50"
                          r="30"
                          fill={COLORS[index % COLORS.length]}
                        ></circle>
                      </svg>
                    </Box>

                    <Text fontSize={["md", "md", "xl"]}>{entry.name}</Text>
                    <Text fontSize={["md", "md", "xl"]} fontWeight="bold">
                      {dataLabel(entry.value)}
                    </Text>
                  </HStack>
                );
              })
            : data}
        </VStack>
      </Box>
    </>
  );
};
