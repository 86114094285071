export const Charts = {
  pages: [
    {
      pageName: "Forsíða",
      header: ["Ríkisreikningur"],
      headerText: [""],
      sections: [
        {
          header: "Hvaðan komu tekjurnar?",
          text: [
            "Ríkið fær mest af sínum tekjum í formi skatta.  Margar stofnanir standa undir hluta af kostnaði með sölu á margvíslegri þjónustu.  Síðasti flokkurinn er svo samansafn af ýmsum liðum s.s. sölu eigna og framlög frá þriðja aðila.",
          ],
          reportNames: ["ReportSectionaec01a29722b3f9a67dd"],
        },
        {
          header: "Hver eru gjöldin?",
          text: [
            "Gjöld ríkisins eru fjölbreyttari.  Stærsti liðurinn eru tilfærslur s.s. barnabætur og húsnæðisbætur, næst kemur launakostnaður og svo kaup á þjónustu s.s. skólar, öldrunarþjónusta og einkarekin heilsugæsla.",
          ],
          reportNames: ["ReportSectionb65f833689da715559b7"],
        },
        {
          header: "Afkoma reksturs ríkisins",
          text: [
            "Mismunur tekna og gjalda mynda afkomu rekstursins. Flest ár hefur afkoman verið jákvæð en áhrif Covid má greinilega sjá á árinu 2020.",
          ],
          reportNames: ["ReportSection09035c13071c89f47acc"],
        },
        {
          header: "Önnur flokkun gjalda",
          text: [
            "Í stað þess að skoða kostnað við rekstur ríkisins eftir kostnaðartegundum þá er hægt að skoða kostnað eftir málefnum.  Þannig er hægt að sjá í hvað peningar ríkisins eru að fara.",
          ],
          reportNames: ["ReportSection8da0ea101516694f553a"],
        },
      ],
    },
  ],
};

export const Reports = {
  pages: [
    {
      pageName: "Málefni",
      header: ["Fjárhagsgögn", "Málefni"],
      headerText: ["Hér er greining á kostnaði eftir málefnaflokkum."],
      sections: [
        {
          header: "Tekjur og gjöld eftir málefni",
          text: [
            "Bókhald ríkisins má skoða í nokkrum víddum. Málefni er ein mikilvægasta víddin því hún gerir okkur kleift að sjá í hvaða peningarnir fara óháð flokkun ráðuneytis eða stofnunar. Yfirmálefnasvið er hæsta flokkun málefna en þar undir eru málefnasvið og loks ýtarlegri sundurliðun í málaflokkum.",
          ],
          reportNames: ["ReportSection4e185a958c38051b08db"],
        },
      ],
    },
    {
      pageName: "Tegund",
      header: ["Fjárhagsgögn", "Tekjur og gjöld ríkisins"],
      headerText: [
        "Á þessari síðu sérðu upplýsingar um fjármál einstakra stofnana.",
      ],
      sections: [
        {
          header: "Tekjur og gjöld eftir tegund",
          text: [
            "Tegund er hin hefðbundna leið til að skoða bókhald. Þannig getum við t.d. séð hversu mikið fer í laun, húsnæði, bifreiðar o.s.frv.",
          ],
          reportNames: ["ReportSectionbd88313e8a373d5437d9"],
        },
      ],
    },
    {
      pageName: "Ráðuneyti",
      header: ["Fjárhagsgögn", "Ráðuneyti"],
      headerText: [
        "Á þessari síðu sérðu upplýsingar um fjármál ráðuneyta ríkisins.",
      ],
      sections: [
        {
          header: "Fjármál ráðuneyta",
          text: [
            "Ráðuneyti Íslands eru efsta stig stjórnsýslu hins opinbera og mynda saman Stjórnarráð Íslands. Hvert ráðuneyti sér um opinbera þjónustu á ákveðnu sviði, svo sem mennta-, utanríkis- og heilbrigðismál. Starfssvið og tilvist ráðuneyta geta breyst samkvæmt stefnu stjórnvalda.",
          ],
          reportNames: ["ReportSection72b699928fbaa9895e89"],
        },
      ],
    },
    {
      pageName: "Ríkisaðilar og verkefni",
      header: ["Fjárhagsgögn", "Ríkisaðilar og verkefni"],
      headerText: [
        "Á þessari síðu sérðu upplýsingar um fjármál einstakra ríkisaðila.",
      ],
      sections: [
        {
          header: "Fjármál ríkisaðila og verkefna",
          text: [
            "Ríkisaðilar eru ein mikilvægasta víddin í bókhaldi ríkisins og fyrir hvern slíkan þarf að gera ársreikning. Þetta er grunneining í bókhaldi sem skiptist annars vegar í stofnanir eins og t.d. Hagstofu og Orkustofnun og hins vegar í verkefni líkt og vaxtagjöld, málefni fatlaðra o.s.frv.",
          ],
          reportNames: ["ReportSectione0dcc341e021cb8dc14c"],
        },
      ],
    },

    {
      pageName: "Lykiltölur",
      header: ["Fjárhagsgögn", "Lykitölur í rekstri ríkisins"],
      headerText: [
        "Á þessari síðu sérðu upplýsingar um fjármál einstakra stofnana.",
      ],
      sections: [
        {
          header: "Lykiltölur",
          text: [
            "Oft er erfitt að skilja tölur, sérstaklega þegar um háar fjárhæðir er að ræða og þá getur verið gagnlegt að setja þær í samhengi. Í stað þess að skoða kostnað við sjúkrahúsaþjónustu í heild er gagnlegt að sjá hver kostnaðurinn er á hvern landsmann. Sama gildir um launakostnað. Þegar launakostnaður milli stofnana er borin saman er rökréttara að skoða meðallaunakostnað í stað heildarlaunakostnaðar. Upplýsingar um fjölda landsmanna eru sóttar af vef Hagstofu Íslands og er meðalfjöldi ársins notaður sem deilistærð.",
          ],
          reportNames: ["ReportSection51f55d5ab0e8ed55af09"],
        },
      ],
    },
    // {
    //   pageName: "Tímaraðir",
    //   header: ["Fjárhagsgögn", "Tímaraðir"],
    //   headerText:
    //     "Á þessari síðu sérðu upplýsingar um fjármál einstakra stofnana.",
    //   sections: [
    //     {
    //       header: "Helstu tímaraðir",
    //       text: "Ríkið rekur margar stofnanir og hægt er að sjá kostnað við flestar þær hér fyrir neðan.",
    //       reportNames: ["ReportSection3fe0f921f2b0b3a03a33"],
    //     },
    //   ],
    // },
  ],
};

export const HRdata = {
  pages: [
    {
      pageName: "Starfsmenn",
      header: ["Mannauðsgögn", "Starfsmenn"],
      headerText: ["Hér er greining á kostnaði eftir málefnaflokkum."],
      sections: [
        {
          header: "Fjöldi starfsmanna og stöðugilda",
          text: [
            "Lykiltölur um mannauð ríkisins þ.e. fjöldi starfsfólks og stöðugilda í heild og eftir kyni. Hægt er að skoða gögnin út frá árum, eftir ráðuneytum og stofnunum þeirra eða út frá hverri og einni stofnun fyrir sig. Hér birtist staðan eins og hún er í lok hvers árs.",
          ],
          reportNames: ["ReportSection49c2e49214eed83451f5"],
        },
      ],
    },
    {
      pageName: "Lýðfræði",
      header: ["Mannauðsgögn", "Lýðfræði"],
      headerText: [
        "Á þessari síðu sérðu upplýsingar um fjármál einstakra stofnana.",
      ],
      sections: [
        {
          header: "Aldursdreifing",
          text: [
            "Aldursdreifing starfsmanna og stöðugilda hjá ríkinu eftir kyni. Hægt er að skoða gögnin út frá árum, ráðuneytum og stofnunum þeirra eða hverri stofnun fyrir sig. Staðan birtist eins og hún er í lok hvers árs.",
          ],
          reportNames: ["ReportSection4388b633bbc983b449da"],
        },
      ],
    },
    {
      pageName: "Menntun",
      header: ["Mannauðsgögn", "Menntun"],
      headerText: [
        "Á þessari síðu sérðu upplýsingar um fjármál ráðuneyta ríkisins.",
      ],
      sections: [
        {
          header: "Menntunarstig",
          text: [
            "Menntunarstig starfsmanna og stöðugilda hjá ríkinu eftir kyni. Menntunarstig er hæsta menntun sem einstaklingur hefur lokið með viðurkenningu. Hægt er að skoða gögnin út frá árum, ráðuneytum og stofnunum þeirra eða hverri stofnun fyrir sig. Staðan birtist eins og hún er í lok hvers árs.",
          ],
          reportNames: ["ReportSection818155c644821c03e905"],
        },
      ],
    },
    {
      pageName: "Stéttarfélög",
      header: ["Mannauðsgögn", "Störf"],
      headerText: ["Störf"],
      sections: [
        {
          header: "Stéttarfélög og bandalög",
          text: [
            "Dreifing starfsmanna og stöðugilda hjá ríkinu eftir bandalögum og stéttarfélögum. Hægt er að skoða gögnin út frá árum. ráðuneytum og stofnunum þeirra. Staðan birtist eins og hún er í lok hvers árs.",
          ],
          reportNames: ["ReportSection5083e0c99043c56f4d48"],
        },
      ],
    },
  ],
};

export const Statements = {
  pages: [
    {
      pageName: "Yfirlit yfir afkomu",
      header: ["Yfirlit yfir afkomu", "Yfirlit yfir rekstrarreikning ársins"],
      headerText: [
        "Á þessari síðu sérðu upplýsingar rekstrarreikning ríkisins.",
      ],
      sections: [
        {
          header: "Yfirlit yfir afkomu",
          text: [
            "Yfirlit yfir afkomu eru unnin samkvæmt ákvæðum laga um opinber fjármál nr. 123/2015 (LOF) og ná yfir fjárreiður A-hluta ríkissjóðs í heild. Byggt er á alþjóðlegum reikningsskilastöðlum fyrir opinbera aðila á rekstrargrunni (IPSAS). Í yfirlitunum birtast tölur með sama hætti og í fjárhagskerfum þannig að tekjur, gjöld og eigið fé (kredit stærðir) eru með mínus (–) formerki en gjöld og eignir eru birt með plús (+) formerki (debit stærðir).",
          ],
          reportNames: ["ReportSectionabaefc2e122aba3aa8a4"],
        },
      ],
    },
    {
      pageName: "Efnahagsreikningur",
      header: ["Efnahagsreikningur", "Yfirlit yfir efnahagsreikning ársins."],
      headerText: [
        "Á þessari síðu sérðu upplýsingar efnahagsreikning ríkisins.",
      ],
      sections: [
        {
          header: "Efnahagsreikningur",
          text: [
            "Þessi yfirlit eru gerð samkvæmt ákvæðum laga um opinber fjármál nr. 123/2015 (LOF) og nær yfir fjárreiður A-hluta ríkissjóðs í heild. Byggt er á alþjóðlegum reikningsskilastöðlum fyrir opinbera aðila á rekstrargrunni (IPSAS). Í þessum yfirlitum eru tölurnar sýndar eins og þær eru í fjárhagskerfum þannig að tekjur, gjöld og eigið fé (kredit stærðir) eru með mínus (–) formerki, en gjöld og eignir eru sýnd með plús (+) formerki (debit stærðir).",
          ],
          reportNames: ["ReportSection058d9fbc7e906cd5c1ae"],
        },
      ],
    },
    // {
    //   pageName: "Rekstrarreikningur2",
    //   header: ["Rekstrarreikningur", "Yfirlit yfir rekstrarreikning ársins"],
    //   headerText: "Á þessari síðu sérðu upplýsingar rekstrarreikning ríkisins.",
    //   sections: [
    //     {
    //       header: "Rekstrarreikningur2",
    //       text: "Hér er birtur rekstrarreikningur ríkisins.",
    //       reportNames: ["ReportSection25b28f8ee95b6720e09a"],
    //     },
    //   ],
    // },
    // {
    //   pageName: "Efnahagsreikningur2",
    //   header: ["Efnahagsreikningur", "Yfirlit yfir efnahagsreikning ársins."],
    //   headerText: "Á þessari síðu sérðu upplýsingar efnahagsreikning ríkisins.",
    //   sections: [
    //     {
    //       header: "Efnahagsreikningur2",
    //       text: "Hér er birtur efnahagsreikningur",
    //       reportNames: ["ReportSectionf78d6374bcb1bd06c0c7"],
    //     },
    //   ],
    // },
  ],
};
