import { Heading, HStack, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";

interface SubmenuProps {
  tabs: {
    pageName: string;
    header: string[];
    headerText: string[];
    sections: {
      header: string;
      text: string[];
      reportNames: string[];
    }[];
  }[];
  changePage: (pageIdx: number) => null | undefined | void;
}

export const Submenu: React.FC<SubmenuProps> = ({ tabs, changePage }) => {
  const [active, setActive] = useState(0);

  const fontSize = useBreakpointValue({
    base: "sm",
    md: "sm",
    lg: "sm",
    xl: "md",
  });
  const handleClick = (idx: number) => {
    changePage(idx);
    setActive(idx);
  };

  return (
    <>
      <HStack
        w="100%"
        boxShadow="0px 30px 60px rgba(0, 0, 0, 0.15)"
        border="1px solid #4E8ECC"
        p={2}
        my={8}
      >
        {tabs.map((tab, index) => {
          return (
            <HStack
              key={index}
              as="button"
              w="100%"
              borderLeft={
                active === index ? "5px solid #4E8ECC" : "5px solid #ffffff"
              }
              borderRight={
                index === tabs.length - 1
                  ? "1px solid #ffffff"
                  : "1px solid #4E8ECC"
              }
              borderRadius="0px"
              _hover={{ borderLeft: "5px solid #5A5E61" }}
              textAlign="center"
              color="headers.gray"
              onClick={() => handleClick(index)}
            >
              {fontSize === "sm" ? (
                <Text size={"11px"} w="100%">
                  {tab.pageName}
                </Text>
              ) : (
                <Heading size={fontSize} w="100%">
                  {tab.pageName}
                </Heading>
              )}
            </HStack>
          );
        })}
      </HStack>
    </>
  );
};
