import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Link as RLink,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageContext } from "../../data/PageContext";
import { HeaderDrawer } from "./HeaderDrawer";

const headerFontSizes = ["1em", "1em", "0.9em", "0.9em", "0.9em", "1em"];

export const Header: React.FC<{}> = (props: any) => {
  const [show] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { pages, setPages } = useContext(PageContext);

  useEffect(() => {}, [pages.page, setPages]);
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="95%"
      h="100px"
      bg="transparent"
      color={["white", "primary.700", "primary.700", "primary.700"]}
    >
      <Flex
        align="center"
        pl={4}
        display={["block", "block", "none", "none", "block", "block"]}
      >
        <RLink href="/">
          <Box h="40px">
            <Image
              h="100%"
              src={"/09103_web_stacked_blue_logo.png"}
              display={["inline", "inline", "none", "none", "inline", "inline"]}
              alt="Fjársýsla ríkisins"
            />
            <Text pl={4} color="black" display="inline">
              Fjársýsla ríksins
            </Text>
          </Box>
        </RLink>
      </Flex>

      <Box display={["block", "block", "none"]}>
        <HeaderDrawer
          isOpen={isOpen}
          onClose={onClose}
          pages={pages}
          setPages={setPages}
        />
        <Button bgColor="primary.700" color="white" onClick={onOpen}>
          Valmynd
        </Button>
      </Box>

      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
        h="100%"
        color="headers.gray"
      >
        <Flex
          align={["center", "center", "center", "center"]}
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          h="100%"
          textTransform="uppercase"
        >
          <Center
            minH="100%"
            px={4}
            borderTop={
              pages.page === 0 ? "5px solid #003D85" : "5px solid #ffffff"
            }
            onClick={() =>
              setPages!((pages) => {
                return { ...pages, page: 0 };
              })
            }
          >
            <Link to="/">
              <Box h="100%" textAlign="center" fontSize={headerFontSizes}>
                Forsíða
              </Box>
            </Link>
          </Center>
          <Center
            minH="100%"
            px={4}
            display={["none", "none", "inherit"]}
            borderTop={
              pages.page === 1 ? "5px solid #003D85" : "5px solid #ffffff"
            }
            onClick={() =>
              setPages!((pages) => {
                return { ...pages, page: 1 };
              })
            }
          >
            <Link to="/fjarhagur">
              <Box h="100%" textAlign="center" fontSize={headerFontSizes}>
                Fjárhagsgögn
              </Box>
            </Link>
          </Center>
          <Center
            minH="100%"
            px={4}
            display={["none", "none", "inherit"]}
            borderTop={
              pages.page === 2 ? "5px solid #003D85" : "5px solid #ffffff"
            }
            onClick={() =>
              setPages!((pages) => {
                return { ...pages, page: 2 };
              })
            }
          >
            <Link to="/mannaudur">
              <Box h="100%" textAlign="center" fontSize={headerFontSizes}>
                Mannauðsgögn
              </Box>
            </Link>
          </Center>
          <Center
            minH="100%"
            px={4}
            display={["none", "none", "inherit"]}
            borderTop={
              pages.page === 3 ? "5px solid #003D85" : "5px solid #ffffff"
            }
            onClick={() =>
              setPages!((pages) => {
                return { ...pages, page: 3 };
              })
            }
          >
            <Link to="/reikningur">
              <Box h="100%" textAlign="center" fontSize={headerFontSizes}>
                Yfirlit yfir afkomu og efnahag
              </Box>
            </Link>
          </Center>
          <Center
            minH="100%"
            px={4}
            display={["none", "none", "inherit"]}
            borderTop={
              pages.page === 4 ? "5px solid #003D85" : "5px solid #ffffff"
            }
            onClick={() =>
              setPages!((pages) => {
                return { ...pages, page: 4 };
              })
            }
          >
            <Link to="/utgefin-gogn">
              <Box h="100%" textAlign="center" fontSize={headerFontSizes}>
                Útgefin gögn
              </Box>
            </Link>
          </Center>
          <Center
            minH="100%"
            px={4}
            borderTop={
              pages.page === 5 ? "5px solid #003D85" : "5px solid #ffffff"
            }
            onClick={() =>
              setPages!((pages) => {
                return { ...pages, page: 5 };
              })
            }
          >
            <Link to="/um-siduna">
              <Box h="100%" textAlign="center" fontSize={headerFontSizes}>
                Um síðuna
              </Box>
            </Link>
          </Center>
        </Flex>
      </Box>
    </Flex>
  );
};
