import {
  Box,
  Center,
  Divider,
  Icon,
  Link,
  Text,
  Image,
  Heading,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BiLink } from "react-icons/bi";
import { PBIReport } from "../components/reports/PBIReport";
import { Hero2 } from "../components/sections/HomeHero";
import { Breadcrumbs } from "../components/ui/Breadcrumbs";
import { ExpandDrawer } from "../components/ui/ExpandDrawerButton";
import { PowerBiDownload } from "../components/ui/PowerBiDownload";
import { ScrollUp } from "../components/ui/ScrollUp";
import { Submenu } from "../components/ui/Submenu";
import { PageContext } from "../data/PageContext";
import { Statements } from "../data/reports";
import Icon31 from "../data/svgs/Icon31.svg";
import Icon32 from "../data/svgs/Icon32.svg";

interface ReikningurProps {}

const paths = ["Yfirlit yfir afkomu og efnahag"];
const url = "/reikningur";

const svgs = [Icon31, Icon32];

export const Reikningur: React.FC<ReikningurProps> = () => {
  const { pages, setPages } = useContext(PageContext);
  const [selectedSection, setSelectedSection] = useState(0);
  const [repSection, setRepSection] = useState(
    "ReportSectionabaefc2e122aba3aa8a4"
  );
  const [yearSelected, setYearSelected] = useState(2020);
  const [update, setUpdate] = useState(true);

  const changePage = (pageIdx: number) => {
    if (selectedSection !== pageIdx) {
      setPages!((pages) => {
        return { ...pages, reikPage: pageIdx };
      });
      setSelectedSection!(pageIdx);
      setRepSection(Statements.pages[pageIdx].sections[0].reportNames[0]);
      setUpdate(!update);
    }
  };

  useEffect(() => {
    if (pages.page !== 3) {
      setPages!((pages) => {
        return { ...pages, page: 3 };
      });
    }
    // eslint-disable-next-line
  }, [setPages]);

  return (
    <>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Breadcrumbs paths={paths} url={url} />
      </Box>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Submenu tabs={Statements.pages} changePage={changePage} />
      </Box>

      <Box pos="relative" w={["90%", "90%", "90%", "90%"]}>
        <Hero2
          pageHeader={Statements.pages[pages.reikPage].sections[0].header}
          headerText={Statements.pages[pages.reikPage].sections[0].text}
          headerAsset=""
          yearSelected={yearSelected}
          setYearSelected={setYearSelected}
          includeYearFilter={false}
          includeKPI={false}
          includeSearch={false}
        />
        <Box
          pos="absolute"
          top={[10, 20, 5, 100, 100, 5]}
          right={[0, 0, 0, 0, 0, 0]}
        >
          <Image
            h={["100px", "200px", "250px", "200px", "300px", "300px"]}
            src={svgs[pages.reikPage]}
            alt="Icon"
            display={["none", "none", "none", "block"]}
          />
        </Box>
      </Box>
      <ScrollUp />

      <Box w={["90%", "90%", "80%", "80%"]} textAlign="left">
        <Box w={["200px"]} my={8}>
          <ExpandDrawer
            type="info"
            heading="Skýringar"
            subheading=""
          ></ExpandDrawer>
        </Box>
      </Box>
      <Box w={["90%", "90%", "80%", "80%"]} textAlign="left" mb={8}>
        <Divider my={8} />
        <Heading size="lg" color="headers.gray">
          Greining
        </Heading>
        <Box w="250px" textAlign="left" mb={4} pt={8} pb={2}>
          <Link href="/utgefin-gogn">
            <Text fontSize="md" display="inline" mr={4}>
              Sækja grunngögn í Excel
            </Text>
            <Icon as={BiLink} />
          </Link>
          <PowerBiDownload />
        </Box>
      </Box>

      <Box w="100%">
        {true ? null : (
          <>
            <PBIReport
              reportSection={
                Statements.pages[selectedSection].sections[0].reportNames[0]
              }
            />
          </>
        )}
      </Box>

      <Center w="100%" h="1080px">
        <iframe
          title={Statements.pages[pages.reikPage].sections[0].header}
          width="100%"
          height="1080px"
          src={
            "https://fjs.data.is/account/public-report?id=bada721d-e81e-4cca-b3cd-9e69617e98a4&reportPageName=" +
            repSection
          }
        ></iframe>
      </Center>

      <Divider m={12} />
    </>
  );
};
