import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const colors = {
  primary: {
    100: "#bfe6ff",
    200: "#8cd3ff",
    300: "#59bfff",
    400: "#26abff",
    500: "#296d98",
    600: "#296d98",
    700: "#296d98",
    800: "#1c49cc",
    900: "#0e2433",
  },
  info: {
    100: "#E5FCF1",
    200: "#27EF96",
    300: "#10DE82",
    400: "#0EBE6F",
    500: "#0CA25F",
    600: "#0A864F",
    700: "#086F42",
    800: "#075C37",
    900: "#064C2E",
  },
  data: {
    100: "#ebe6fe",
    200: "#d6ccfd",
    300: "#c2b3fc",
    400: "#0EBE6F",
    500: "#9980fa",
    600: "#8a73e1",
    700: "#003D85",
    800: "#6b5aaf",
    900: "#5c4d96",
  },
  action: {
    100: "#ffedb8",
    200: "#ffe189",
    300: "#ffd559",
    400: "#ffc92a",
    500: "#ffc312",
    600: "#e6b010",
    700: "#cc9c0e",
    800: "#b3890d",
    900: "#99750b",
  },
  headers: {
    gray: "#5A5E61",
    blue: "#4E8ECC",
  },
};

const fonts = {
  heading: "FiraGO",
  body: "FiraGO",
};

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "55em",
  xl: "62em",
  xxl: "80em",
  "2xl": "96em",
});

export const customTheme = extendTheme({ colors, fonts, breakpoints });
