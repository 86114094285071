import { Box, Image } from "@chakra-ui/react";
import React from "react";
import ScrollToTop from "react-scroll-up";
import Skruna from "../../data/svgs/skruna.svg";

interface ScrollUpProps {}

export const ScrollUp: React.FC<ScrollUpProps> = (data) => (
  <Box display={["none", "block"]} zIndex={2}>
    <ScrollToTop showUnder={160}>
      <Image
        w={["100px", "100px", "150px", "200px"]}
        src={Skruna}
        alt="Icon"
        display={["none", "none", "none", "block"]}
      />
    </ScrollToTop>
  </Box>
);
