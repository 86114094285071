import {
  Box,
  Flex,
  HStack,
  Image,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Charts } from "../../data/reports";
import IconF3 from "../../data/svgs/IconF3.svg";
import { renderActiveShape } from "../../utils/charts";
import { spacing } from "../../utils/utils";
import { Legend } from "../charts/Legend";
import { DownloadExcel } from "../ui/DownloadExcel";
import { ExpandDrawer } from "../ui/ExpandDrawerButton";
import { HeadingCard } from "../ui/HeadingCard";

interface HomeCostChartProps {
  data: {
    name: string;
    value: number;
  }[];
  graphTitle: string;
  year: number;
  setYear: React.Dispatch<React.SetStateAction<number>>;
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export const HomeCostChart: React.FC<HomeCostChartProps> = ({
  data,
  graphTitle,
  year,
  setYear,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index: number) => {
    setActiveIndex(index);
  };

  const handleClick = (item, idx) => {
    //history.push("/fjarhagur");
    let page: string = "";
    switch (idx) {
      case 0:
        page = "/fjarhagur?page=1";
        break;
      case 1:
        page = "/fjarhagur?page=1";
        break;
      case 2:
        page = "/fjarhagur?page=1";
        break;
      case 3:
        page = "/fjarhagur";
        break;
    }

    window.open(
      page,
      "_blank" // <- opens the link in a new tab or window.
    );
  };
  const bp = useBreakpointValue({ base: 0, xl: 1 });

  return (
    <Box mb={8}>
      <Box
        my={[3, 3, 3, 3]}
        py={[3, 3, 3, 3]}
        maxW={spacing.WIDE}
        textAlign="left"
      >
        <Box pos="relative">
          <HeadingCard
            heading={Charts.pages[0].sections[1].header}
            panel={Charts.pages[0].sections[1].text}
          />
          <Box
            pos="absolute"
            top={[10, 20, 5, 100, 100, 5]}
            right={[0, 0, 0, 0, 0, 0]}
          >
            <Image
              h={["100px", "100px", "150px", "200px", "300px", "300px"]}
              src={IconF3}
              alt="Icon"
              display={["none", "none", "none", "block"]}
            />
          </Box>
        </Box>
        <DownloadExcel url="test" />
        <Box w={["200px"]} my={8}>
          <ExpandDrawer
            type="info"
            heading="Skýringar"
            subheading=""
          ></ExpandDrawer>
        </Box>
      </Box>
      {bp === 0 ? (
        <VStack w="100%" alignItems="center">
          <Legend
            graphTitle={graphTitle}
            year={year}
            setYear={setYear}
            data={data}
          />
          <VStack
            w={["40%", "100%", "100%", "100%"]}
            display={["none", "block"]}
          >
            <ResponsiveContainer width="99%" height={500}>
              <PieChart>
                <Pie
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius="40%"
                  outerRadius="65%"
                  fill="#8884d8"
                  dataKey="value"
                  onMouseEnter={onPieEnter}
                  onClick={(item) => handleClick(item, 1)}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </VStack>
        </VStack>
      ) : (
        <HStack w={spacing.WIDE} alignItems="center">
          <Flex
            w={["40%", "40%", "40%", "40%"]}
            align="left"
            alignItems="left"
            textAlign="left"
            direction="column"
          >
            <Legend
              graphTitle={graphTitle}
              year={year}
              setYear={setYear}
              data={data}
            />
          </Flex>

          <VStack w={["80%", "80%", "80%", "100%", "100%"]} mb={4}>
            <>
              <ResponsiveContainer width="99%" height={600}>
                <PieChart>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    cx="50%"
                    cy="50%"
                    innerRadius="40%"
                    outerRadius="65%"
                    fill="#8884d8"
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                    onClick={(item) => handleClick(item, 1)}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </>
          </VStack>
        </HStack>
      )}
    </Box>
  );
};
