import { Heading, Icon, VStack } from "@chakra-ui/react";
import React from "react";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getMaxYear } from "../../utils/utils";

interface YearSelecterProps {
  yearSelected: number;
  setYearSelected: React.Dispatch<React.SetStateAction<number>>;
}

export const YearSelecter: React.FC<YearSelecterProps> = ({
  yearSelected,
  setYearSelected,
}) => {
  const currentYear = getMaxYear();
  
  return (
    <>
      <VStack h="100%">
        <Icon
          boxSize="50px"
          m="0"
          as={BsChevronUp}
          color={yearSelected === currentYear ? "gray.100" : "headers.blue"}
          onClick={() => {
            yearSelected === currentYear
              ? setYearSelected(yearSelected)
              : setYearSelected(yearSelected + 1);
          }}
        />
        <Heading
          size="md"
          borderBottom={"3px solid #5A5E61"}
          textAlign="center"
        >
          Sía eftir ári
        </Heading>
        <Icon
          boxSize="50px"
          m="0"
          as={BsChevronDown}
          color={yearSelected <= 2017 ? "gray.100" : "headers.blue"}
          onClick={() => {
            yearSelected <= 2017
              ? setYearSelected(yearSelected)
              : setYearSelected(yearSelected - 1);
          }}
        />
      </VStack>
    </>
  );
};
