import {
  Box,
  Divider,
  Heading,
  HStack,
  Icon,
  Link,
  Text,
  Image,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";
import { DataSection } from "../components/sections/DataSection";
import { Breadcrumbs } from "../components/ui/Breadcrumbs";
import { ScrollUp } from "../components/ui/ScrollUp";
import { Submenu } from "../components/ui/Submenu";
import { api_key } from "../configs/config";
import { PageContext } from "../data/PageContext";
import Icon41 from "../data/svgs/Icon41.svg";
import Icon42 from "../data/svgs/Icon42.svg";
import Icon43 from "../data/svgs/Icon43.svg";
import Icon44 from "../data/svgs/Icon44.svg";
import Icon45 from "../data/svgs/Icon45.svg";
import { getMonth } from "../utils/utils";

interface EfniProps {}

const url = "/utgefin-gogn";

const svgs = [Icon41, Icon42, Icon43, Icon44, Icon45];

const PAGES = [
  {
    pageName: "Grunngögn og víddir",
    header: ["Grunngögn og víddir"],
    headerText: [""],
    sections: [
      {
        header: "Gögn og víddir af síðunni",
        text: [
          "Hér getur þú sótt grunngögnin og víddir sem notaðar eru í samantektir og greiningu á þessari síðu.",
        ],
        reportNames: [""],
        links: [
          {
            text: "Grunngögn fyrir 2020",
            link: "https://google.com",
            type: "download",
          },
          {
            text: "Víddir fyrir 2020",
            link: "https://google.com",
            type: "download",
          },
          {
            text: "Grunngögn fyrir 2019",
            link: "https://google.com",
            type: "download",
          },
          {
            text: "Grunngögn fyrir 2018",
            link: "https://google.com",
            type: "download",
          },
          {
            text: "Grunngögn fyrir 2017",
            link: "https://google.com",
            type: "download",
          },
          {
            text: "Grunngögn fyrir 2016",
            link: "https://google.com",
            type: "download",
          },
          {
            text: "Grunngögn fyrir 2015",
            link: "https://google.com",
            type: "download",
          },
        ],
      },
    ],
  },
  {
    pageName: "Séryfirlit",
    header: ["Grunngögn og víddir"],
    headerText: [""],
    sections: [
      {
        header: "Séryfirlit og ítargögn",
        text: [
          "Hér má finna hlekki á þau yfirlit sem áður voru birt á rikireikningur.is.  Þessi yfirlit spanna árin 2004–2016.",
        ],
        reportNames: [""],
        links: [
          {
            text: "Ríkisreikningur [gamla útgáfa]",
            link: "http://gamli.rikisreikningur.is/",
            type: "external",
          },
          {
            text: "Séryfirlit 1 - Tekjur ríkissjóðs - Samantekt",
            link: "http://gamli.rikisreikningur.is/Seryfirlit1/Default.aspx",
            type: "external",
          },
          {
            text: "Séryfirlit 1 - Tekjur ríkissjóðs - Ítarlegt",
            link: "http://gamli.rikisreikningur.is/Seryfirlit1/Itarlegt.aspx?ar=",
            type: "external",
          },
          {
            text: "Séryfirlit 2 - Gjöld ráðuneyta og stofnana - Samantekt",
            link: "http://gamli.rikisreikningur.is/Seryfirlit2/",
            type: "external",
          },
          {
            text: "Séryfirlit 2 - Gjöld ráðuneyta og stofnana - Nánar",
            link: "http://gamli.rikisreikningur.is/Seryfirlit2/Nanar.aspx",
            type: "external",
          },
          {
            text: "COFOG - Samantekt",
            link: "http://gamli.rikisreikningur.is/Malaflokkar/",
            type: "external",
          },
          {
            text: "COFOG - Ítarlegt",
            link: "http://gamli.rikisreikningur.is/Malaflokkar/Itarlegt.aspx",
            type: "external",
          },
        ],
      },
    ],
  },
  {
    pageName: "Ársreikningar og opnir reikningar",
    header: ["Grunngögn og víddir"],
    headerText: [""],
    sections: [
      {
        header: "Ársreikningar og opnir reikningar",
        text: [
          "Hér má finna hlekki á ársreikninga á PDF sniði og önnur gögn af opnirreikningar.is.",
        ],
        reportNames: [""],
        links: [
          {
            text: "Ríkisreikningur",
            link: "https://www.fjs.is/utgefid-efni/rikisreikningur/",
            type: "external",
          },
          {
            text: "Mánaðaryfirlit ríkissjóðs",
            link: "https://www.fjs.is/utgefid-efni/manadaryfirlit-rikissjods/",
            type: "external",
          },
          {
            text: "Ársreikningar A-hluti ríkissjóðs",
            link: "https://arsreikningar.rikisreikningur.is/stofnun",
            type: "external",
          },
          {
            text: "Ársreikningar B- og C-hluti ríkissjóðs",
            link: "https://arsreikningar.rikisreikningur.is/fyrirtaeki",
            type: "external",
          },
          {
            text: "Ársreikningar Sveitarfélög",
            link: "https://arsreikningar.rikisreikningur.is/sveitarfelag",
            type: "external",
          },
          {
            text: "Opnir reikningar ríkisins",
            link: "http://www.opnirreikningar.is/",
            type: "external",
          },
        ],
      },
    ],
  },
];

type File = {
  nafn: string;
  tegund: string;
  ar: number;
  timabil: number;
};

export const Efni: React.FC<EfniProps> = () => {
  const { pages, setPages } = useContext(PageContext);
  const [tab, setTab] = useState(0);
  const [files, setFiles] = useState<File[]>([
    {
      nafn: "",
      tegund: "",
      ar: 0,
      timabil: 0,
    },
  ]);
  const [oldFiles, setOldFiles] = useState<File[]>([
    {
      nafn: "",
      tegund: "",
      ar: 0,
      timabil: 0,
    },
  ]);
  const [ffFiles, setFFFiles] = useState<File[]>([
    {
      nafn: "",
      tegund: "",
      ar: 0,
      timabil: 0,
    },
  ]);
  const [loading, setLoading] = useState(true);

  const changePage = (pageIdx: number) => {
    setTab(pageIdx);

    return null;
  };

  const getFilesSorted = (results: File[]) => {
    const nf = results.filter((file) => {
      return file.nafn.includes("Rikisreikningur");
    });

    const of = results.filter((file) => {
      return file.nafn.includes("Heimamundur");
    });

    const ff = results.filter((file) => {
      return file.nafn.includes("Forsidugogn");
    });

    return [
      nf.sort((a, b) => (a.ar > b.ar ? 1 : -1)),
      of.sort((a, b) => (a.ar > b.ar ? 1 : -1)),
      ff,
    ];
  };

  const getFilesSection = () => {
    return (
      <>
        <Heading my={4} textAlign="center" size="lg">
          Ríkisreikningur 2017-{currYear}
        </Heading>
        {files.map((link, index) => {
          if (index % 2 !== 0) {
            return <></>;
          }
          return (
            <>
              {fontSize === "sm" ? (
                <VStack py={2}>
                  <Icon as={BiDownload} />
                  <Heading size="md" mx={4}>
                    {"Ríkisreikningur gögn árið " + link.ar}{" "}
                    {currYear === link.ar
                      ? " að " + getMonth(link.timabil)
                      : ""}
                  </Heading>
                  <Link
                    onClick={() =>
                      handleClick(link.nafn, link.ar, link.tegund, link.timabil)
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                  <Link
                    onClick={() =>
                      handleClick(
                        files[index + 1].nafn,
                        files[index + 1].ar,
                        files[index + 1].tegund,
                        files[index + 1].timabil
                      )
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index + 1].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                </VStack>
              ) : (
                <HStack py={2}>
                  <Icon as={BiDownload} />
                  <Heading size="md" mx={4}>
                    {"Ríkisreikningur gögn árið " + link.ar}{" "}
                    {currYear === link.ar
                      ? " að " + getMonth(link.timabil)
                      : ""}
                  </Heading>
                  <Link
                    onClick={() =>
                      handleClick(link.nafn, link.ar, link.tegund, link.timabil)
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                  <Link
                    onClick={() =>
                      handleClick(
                        files[index + 1].nafn,
                        files[index + 1].ar,
                        files[index + 1].tegund,
                        files[index + 1].timabil
                      )
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index + 1].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                </HStack>
              )}
            </>
          );
        })}
        <Heading my={4} textAlign="center" size="lg">
          Eldri gögn
        </Heading>
        {oldFiles.map((link, index) => {
          if (index !== 0) return <></>;
          return (
            <>
              {fontSize === "sm" ? (
                <VStack py={2}>
                  <Icon as={BiDownload} />
                  <Heading size="md" mx={4}>
                    {"Ríkisreikningur gögn árið 2004-2016"}
                  </Heading>
                  <Link
                    onClick={() =>
                      handleClick(link.nafn, link.ar, link.tegund, link.timabil)
                    }
                  >
                    <Text>{files ? "Sækja xlsx skrá" : "villa"} </Text>
                  </Link>
                </VStack>
              ) : (
                <HStack py={2}>
                  <Icon as={BiDownload} />
                  <Heading size="md" mx={4}>
                    {"Ríkisreikningur gögn árið 2004-2016"}
                  </Heading>
                  <Link
                    onClick={() =>
                      handleClick(link.nafn, link.ar, link.tegund, link.timabil)
                    }
                  >
                    <Text>{files ? "Sækja xlsx skrá" : "villa"} </Text>
                  </Link>
                </HStack>
              )}
            </>
          );
        })}
        <Heading my={4} textAlign="center" size="lg">
          Forsíðugögn
        </Heading>
        {ffFiles.map((link, index) => {
          if (index % 2 !== 0) {
            return <></>;
          }
          return (
            <>
              {fontSize === "sm" ? (
                <VStack py={2}>
                  <Icon as={BiDownload} />
                  <Heading size="md" mx={4}>
                    Forsíðu gögn öll tímabil
                  </Heading>
                  <Link
                    onClick={() =>
                      handleClick(link.nafn, link.ar, link.tegund, link.timabil)
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                  <Link
                    onClick={() =>
                      handleClick(
                        files[index + 1].nafn,
                        files[index + 1].ar,
                        files[index + 1].tegund,
                        files[index + 1].timabil
                      )
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index + 1].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                </VStack>
              ) : (
                <HStack py={2}>
                  <Icon as={BiDownload} />
                  <Heading size="md" mx={4}>
                    Forsíðugögn öll tímabil
                  </Heading>
                  <Link
                    onClick={() =>
                      handleClick(link.nafn, link.ar, link.tegund, link.timabil)
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                  <Link
                    onClick={() =>
                      handleClick(
                        files[index + 1].nafn,
                        files[index + 1].ar,
                        files[index + 1].tegund,
                        files[index + 1].timabil
                      )
                    }
                  >
                    <Text>
                      {files
                        ? "Sækja " + files[index + 1].tegund + " skrá"
                        : "villa"}{" "}
                    </Text>
                  </Link>
                </HStack>
              )}
            </>
          );
        })}
      </>
    );
  };
  useEffect(() => {
    fetch("https://rikisreikningurapi.azurewebsites.net/api/FJS/Data/skrar", {
      method: "GET",
      headers: {
        accept: "text/plain",
        "X-Api-Key": api_key, // TODO: Move to env variable.
      },
    })
      .then((response) => {
        response
          .json()
          .then((data) => {
            const results = JSON.parse(data[0]);
            let nf = getFilesSorted(results.skrar)[0];
            let of = getFilesSorted(results.skrar)[1];
            let ff = getFilesSorted(results.skrar)[2];

            setFiles(nf);
            setOldFiles(of);
            setFFFiles(ff);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log("Error fetching útgefin gögn", err);
      });
  }, []);

  useEffect(() => {
    if (pages.page !== 4) {
      setPages!({ ...pages, page: 4 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (
    file: string,
    year: number,
    type: string,
    timabil: number
  ) => {
    if (type === "xlsx") type = "xslx"; // Typo in the API endpoint
    fetch(
      "https://rikisreikningurapi.azurewebsites.net/api/Files/Rikisreikningur/" +
        file,
      {
        method: "GET",
        headers: {
          accept: "text/plain",
          "X-Api-Key": api_key, // TODO: Move to env variable.
        },
      }
    )
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = file;
          a.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const currYear = new Date().getFullYear();

  const fontSize = useBreakpointValue({
    base: "sm",
    md: "sm",
    lg: "sm",
    xl: "md",
  });
  return (
    <>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Breadcrumbs paths={["Útgefin gögn", PAGES[tab].header[0]]} url={url} />
      </Box>
      <Box w={["90%", "90%", "80%", "80%"]}>
        <Submenu tabs={PAGES} changePage={changePage} />
      </Box>

      <Box pos="relative" w="100%" my={20}>
        <DataSection
          pageHeader={PAGES[tab].sections[0].header}
          headerText={PAGES[tab].sections[0].text}
          headerAsset=""
        />
        <Box
          pos="absolute"
          top={[10, 20, 5, 100, 100, 5]}
          right={[0, 0, 0, 0, 0, 0]}
        >
          <Image
            h={["100px", "200px", "250px", "200px", "300px", "300px"]}
            src={svgs[tab]}
            alt="Icon"
            display={["none", "none", "none", "block"]}
          />
        </Box>
      </Box>
      <ScrollUp />
      {loading ? (
        <>Sæki skrár...</>
      ) : (
        <Box w={["90%", "90%", "80%", "75%"]} mb={8} textAlign="left">
          {tab === 0 ? (
            <Text fontSize="lg" fontStyle="italic">
              Skjölin eru stór og gæti tekið nokkrar sekúndur fyrir niðurhalið
              að byrja.
            </Text>
          ) : (
            <></>
          )}
          <Box my={8}>
            {tab === 0 ? (
              <>{getFilesSection()}</>
            ) : (
              PAGES[tab].sections.map((section) => {
                return (
                  <>
                    {section.links.map((link) => {
                      return (
                        <Link href={link.link} isExternal={true}>
                          <Text>{link.text}</Text>
                        </Link>
                      );
                    })}
                  </>
                );
              })
            )}
          </Box>
          <Divider mb={20} />
        </Box>
      )}
    </>
  );
};
