import { Button } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { PageContext } from "../../data/PageContext";

interface DisclaimerButtonProps {}

export const DisclaimerButton: React.FC<DisclaimerButtonProps> = () => {
  const [toggled, setToggled] = useState(false);
  const { drawer } = useContext(PageContext);

  const handleOpen = () => {
    drawer!.onOpen();
    setToggled(!toggled);
  };
  return (
    <>
      <Button variant="outline" colorScheme="orange" onClick={handleOpen}>
        Fyrirvarar um upplýsingar
      </Button>
    </>
  );
};
