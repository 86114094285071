import { Box, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { dataLabel } from "../../utils/charts";

interface ProfitLegendProps {
  graphTitle: string;
  data: {
    year: string;
    revenue: number;
    costs: number;
    profit: number;
  }[];
}

const COLORS = ["#4E8ECC", "#C75F93", "#2A3E5A", "#FF8042"];

export const ProfitLegend: React.FC<ProfitLegendProps> = ({
  graphTitle,
  data,
}) => {
  const [minYear, setMinYear] = useState(9999);
  const [maxYear, setMaxYear] = useState(0);

  useEffect(() => {
    let currMin = 9999;
    let currMax = 0;
    data.forEach((year) => {
      if (parseInt(year.year) < currMin) currMin = parseInt(year.year);
      else if (parseInt(year.year) > currMax) currMax = parseInt(year.year);
    });

    if (currMin > 2016) {
      setMinYear(currMin);
    }

    setMaxYear(currMax);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Box bgColor="white" w={["80%", "80%", "80%", "60%"]}>
        <Box textAlign="left" borderBottom="1px solid #000000" mb={4} py={2}>
          <Heading fontSize="xl">
            {graphTitle + minYear + "-" + maxYear}
          </Heading>
        </Box>
        <VStack textAlign="left" w="100%">
          {["Tekjur", "Kostnaður", "Afkoma"].map((entry, index) => {
            return (
              <HStack key={"profit-legend-" + index} maxH="50px" w="100%">
                {entry === "Afkoma" ? (
                  <Box>
                    <svg width="30" height="30" viewBox="0 0 100 100">
                      <path
                        d="M 20 50 H 100  Z"
                        stroke={COLORS[index % COLORS.length]}
                        strokeWidth={10}
                      />
                      <circle
                        cx="60"
                        cy="50"
                        r="25"
                        stroke={COLORS[index % COLORS.length]}
                        fill="white"
                        strokeWidth={10}
                      ></circle>
                    </svg>
                  </Box>
                ) : (
                  <Box>
                    <svg width="30" height="30" viewBox="0 0 100 100">
                      <circle
                        cx="60"
                        cy="50"
                        r="30"
                        fill={COLORS[index % COLORS.length]}
                      ></circle>
                    </svg>
                  </Box>
                )}

                <VStack w={["100%"]} alignItems="baseline">
                  <Text fontSize={["md", "md", "xl"]}>{entry} </Text>

                  <Text
                    fontSize={["md", "md", "xl"]}
                    fontWeight="bold"
                    display={["block", "block", "none", "none"]}
                  >
                    {dataLabel(entry)}
                  </Text>
                </VStack>
              </HStack>
            );
          })}
        </VStack>
      </Box>
    </>
  );
};
