import {
  Box,
  Center,
  Divider,
  Heading,
  Icon,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BiLink } from "react-icons/bi";
import { PBIReport } from "../components/reports/PBIReport";
import { Hero2 } from "../components/sections/HomeHero";
import { Breadcrumbs } from "../components/ui/Breadcrumbs";
import { ExpandDrawer } from "../components/ui/ExpandDrawerButton";
import { PowerBiDownload } from "../components/ui/PowerBiDownload";
import { ScrollUp } from "../components/ui/ScrollUp";
import { Submenu } from "../components/ui/Submenu";
import { PageContext } from "../data/PageContext";
import { Reports } from "../data/reports";
import Icon11 from "../data/svgs/Icon11.svg";
import Icon12 from "../data/svgs/Icon12.svg";
import Icon13 from "../data/svgs/Icon13.svg";
import Icon14 from "../data/svgs/Icon14.svg";
import Icon15 from "../data/svgs/Icon15.svg";
import Icon16 from "../data/svgs/Icon16.svg";

interface ReportsPageProps {}

const paths = ["Fjárhagsgögn"];
const url = "/fjarhagur";

const svgs = [Icon11, Icon12, Icon13, Icon14, Icon15, Icon16];

export const ReportsPage: React.FC<ReportsPageProps> = () => {
  const { pages, setPages } = useContext(PageContext);
  const [selectedSection] = useState(0);
  // const history = useHistory();
  const [repSection, setRepSection] = useState(
    "ReportSection4e185a958c38051b08db"
  );
  const [update, setUpdate] = useState(true);
  const [yearSelected, setYearSelected] = useState(2020);

  const changePage = (pageIdx: number) => {
    if (pages.reportPage === pageIdx) return null;
    setPages!({ ...pages, reportPage: pageIdx });
    setRepSection(
      Reports.pages[pageIdx].sections[selectedSection].reportNames[0]
    );
    setUpdate(!update);
  };

  useEffect(() => {
    if (pages.page !== 1) {
      setPages!((pages) => {
        return { ...pages, page: 1 };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <>
        <Box w={["90%", "90%", "80%", "80%"]}>
          <Breadcrumbs paths={paths} url={url} />
        </Box>
        <Box w={["90%", "90%", "80%", "80%"]}>
          <Submenu tabs={Reports.pages} changePage={changePage} />
        </Box>

        <Box pos="relative" w={["90%", "90%", "90%", "90%"]}>
          <Hero2
            pageHeader={
              Reports.pages[pages.reportPage].sections[selectedSection].header
            }
            headerText={
              Reports.pages[pages.reportPage].sections[selectedSection].text
            }
            headerAsset=""
            yearSelected={yearSelected}
            setYearSelected={setYearSelected}
            includeYearFilter={false}
            includeKPI={false}
            includeSearch={false}
          />
          <Box
            pos="absolute"
            top={[10, 20, 5, 100, 100, 5]}
            right={[0, 0, 0, 0, 0, 0]}
          >
            <Image
              h={["100px", "200px", "250px", "200px", "300px", "300px"]}
              src={svgs[pages.reportPage]}
              alt="Icon"
              display={["none", "none", "none", "block"]}
            />
          </Box>
        </Box>
        <ScrollUp />
        <Box w={["90%", "90%", "80%", "80%"]} textAlign="left" mb={8}>
          <Box w={["200px"]} my={8}>
            <ExpandDrawer
              type="info"
              heading="Skýringar"
              subheading=""
            ></ExpandDrawer>
          </Box>
        </Box>
        <Box w={["90%", "90%", "80%", "80%"]} textAlign="left" mb={8}>
          <Divider my={8} />
          <Heading size="lg" color="headers.gray">
            Greining
          </Heading>
          <Box w="250px" textAlign="left" mb={4} pt={8} pb={2}>
            <Link href="/utgefin-gogn">
              <Text fontSize="md" display="inline" mr={4}>
                Sækja grunngögn í Excel
              </Text>
              <Icon as={BiLink} />
            </Link>
            <PowerBiDownload />
          </Box>
        </Box>

        <Box w="100%">
          {true ? null : (
            <>
              <PBIReport
                reportSection={
                  Reports.pages[pages.reportPage].sections[selectedSection]
                    .reportNames[0]
                }
              />
            </>
          )}
        </Box>

        <Center w="100%" h="1080px">
          <iframe
            title={Reports.pages[pages.reportPage].sections[0].header}
            width="100%"
            height="1080px"
            src={
              "https://fjs.data.is/account/public-report?id=bada721d-e81e-4cca-b3cd-9e69617e98a4&reportPageName=" +
              repSection
            }
          ></iframe>
        </Center>
        <Divider m={12} />
      </>
    </>
  );
};
