import { config } from "../configs/config";
import guid from "guid";

export const spacing = {
  FULL: ["100%", "100%", "100%", "100%"],
  WIDE: ["80%", "80%", "90%", "90%"],
  MEDIUM: ["80%", "80%", "80%", "80%"],
};

export const getMonth = (month: number) => {
  const months = [
    "janúar",
    "febrúar",
    "mars",
    "apríl",
    "maí",
    "júní",
    "júlí",
    "ágúst",
    "september",
    "október",
    "nóvember",
    "desember",
  ];

  return months[month - 1];
};

export const getMaxYear = () => {
  //config.maxYear;
  // if maxYear is null return 2022 else return maxYear
  return config.maxYear ? Number(config.maxYear) : new Date().getFullYear();
}

export const getAuthHeader = (accessToken) => {
  // Function to append Bearer against the Access Token
  return "Bearer ".concat(accessToken);
};

export const validateConfig = () => {
  // Validation function to check whether the Configurations are available in the config.json file or not

  if (!config.authenticationMode) {
    return "AuthenticationMode is empty. Please choose MasterUser or ServicePrincipal in config.json.";
  }

  if (
    config.authenticationMode.toLowerCase() !== "masteruser" &&
    config.authenticationMode.toLowerCase() !== "serviceprincipal"
  ) {
    return "AuthenticationMode is wrong. Please choose MasterUser or ServicePrincipal in config.json";
  }

  if (!config.clientId) {
    return "ClientId is empty.";
  }

  if (!guid.isGuid(config.clientId)) {
    return "ClientId must be a Guid object.";
  }

  if (!config.reportId) {
    return "ReportId is empty.";
  }

  if (!config.workspaceId) {
    return "WorkspaceId is empty.";
  }

  if (!guid.isGuid(config.workspaceId)) {
    return "WorkspaceId must be a Guid object. Please select a workspace you own and fill its Id in config.json.";
  }

  if (!config.authorityUri) {
    return "AuthorityUri is empty. Please fill valid AuthorityUri in config.json.";
  }

  if (config.authenticationMode.toLowerCase() === "masteruser") {
    if (!config.pbiUsername || !config.pbiUsername.trim()) {
      return "PbiUsername is empty. Please fill Power BI username in config.json.";
    }

    if (!config.pbiPassword || !config.pbiPassword.trim()) {
      return "PbiPassword is empty. Please fill password of Power BI username in config.json.";
    }
  } else if (config.authenticationMode.toLowerCase() === "serviceprincipal") {
    if (!config.clientSecret || !config.clientSecret.trim()) {
      return "ClientSecret is empty. Please fill Power BI ServicePrincipal ClientSecret in config.json.";
    }

    if (!config.tenantId) {
      return "TenantId is empty. Please fill the TenantId in config.json.";
    }

    if (!guid.isGuid(config.tenantId)) {
      return "TenantId must be a Guid object. Please select a workspace you own and fill its Id in config.json.";
    }
  }
};
