import { createContext } from "react";

interface Page {
  pages: {
    page: number;
    reportPage: number;
    hrPage: number;
    reikPage: number;
  };
  setPages?: React.Dispatch<
    React.SetStateAction<{
      page: number;
      reportPage: number;
      hrPage: number;
      reikPage: number;
    }>
  >;
  drawer?: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
}

export const PageContext = createContext<Page>({
  pages: {
    page: 0,
    reportPage: 0,
    hrPage: 0,
    reikPage: 0,
  },
  setPages: undefined,
  drawer: undefined,
});
