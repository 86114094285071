import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";

interface PowerBiDownloadProps {}

export const PowerBiDownload: React.FC<PowerBiDownloadProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <>
        <Button
          my={4}
          colorScheme="teal"
          size="sm"
          variant="outline"
          onClick={onOpen}
        >
          Leiðbeiningar
        </Button>
        <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              Hér birtast helstu leiðbeiningar fyrir Power BI skýrsluna sem
              birtist á þessari síðu
            </DrawerHeader>

            <DrawerBody>
              <Heading>Sækja gögn úr Power BI gröfum og töflum</Heading>
              <Text fontSize="16px" mb={4}>
                Skýrslan á þessari síðu er gerð með Power BI tólinu frá
                Microsoft. Til að sækja gögnin úr einstaka gröfum eða töflum
                innan skýrslunnar þarf að færa músina yfir hægra hornið á
                grafinu/töflunni og þá birtast nokkur tákn. Táknið með þremur
                punktum opnar aðra valmynd þar sem hægt er að velja „Export
                data“. Ef ýtt er á þann valmöguleika opnast gluggi þar sem hægt
                er að sækja gögnin sem eru á bakvið það graf eða þá töflu.
              </Text>
              <Text fontSize="16px" mb={8}>
                Svona ætti valmyndin að líta út:
              </Text>
              <Image src="/power-bi-export.jpg" />
              <Divider my={12} />
              <Heading>Högun á stöplaritum</Heading>
              <Text fontSize="16px" mb={4}>
                Þegar smellt er á vídd í grafi, t.d. ákveðna stofnun eða
                ráðuneyti, þá getur það haft áhrif á birtingu annarra grafa. Sum
                stöplarit sýna sem dæmi hvernig niðurstöður eru fyrir valda vídd
                í samanburði við heildina.
              </Text>
              <Text fontSize="16px" mb={8}>
                Þetta má sjá hér:
              </Text>
              <Image src="/barchart_filtered.jpg" />
              <Text fontSize="16px" mb={4}>
                Ef tölurnar eftir að vídd er valin eru of litlar getur verið að
                þær hverfi úr grafi. Þá er hægt að færa músarbendilinn yfir
                grafið til þess að sjá hvaða tölur eru á bakvið.
              </Text>
              <Text fontSize="16px" mb={8}>
                Þetta má sjá hér:
              </Text>
              <Image src="/tooltip.jpg" />
              <Divider my={12} />
              <Heading>Sprengja út töflur og víddir</Heading>
              <Text fontSize="16px" mb={4}>
                Í töflum og víddum birtast oft plúsar eða örvar við hliðina á
                röðinni. Það er merki um að hægt sé að sprengja út röðina (opna
                hana á næsta skref fyrir neðan).
              </Text>
              <Text fontSize="16px" mb={8}>
                Dæmi um þetta eru:
              </Text>
              <Image src="/expand_filter.jpg" />
              <Image src="/expand_table.jpg" />
              <Divider my={12} />
              <Heading>Flakka innan síðu</Heading>
              <Text fontSize="16px" mb={4}>
                Á sumum síðum eru takkar sem hægt er að smella á og sjá önnur
                gröf/töflur.
              </Text>
              <Text fontSize="16px" mb={8}>
                Dæmi um þetta er:
              </Text>
              <Image src="/select_button.jpg" />
              <Divider my={12} />
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Loka
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </>
    </Box>
  );
};
