import { useDisclosure } from "@chakra-ui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Switch, useLocation } from "react-router-dom";
import { Layout } from "./components/sections/Layout";
import { SideDrawer } from "./components/sections/SideDrawer";
import { Loader } from "./components/ui/Loader";
import { api_key } from "./configs/config";
import { PageContext } from "./data/PageContext";
import { About } from "./pages/About";
import { Efni } from "./pages/Efni";
import { ErrorPage } from "./pages/Error";
import { Home } from "./pages/Home";
import { HR } from "./pages/HRPage";
import { Reikningur } from "./pages/Reikningur";
import { ReportsPage } from "./pages/ReportsPage";
import { mapData, mapMalefni } from "./utils/charts";

export const App = () => {
  // Define app state.
  const [graphData, setGraphData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const drawer = useDisclosure();
  const [pages, setPages] = useState({
    page: 0,
    reportPage: 0,
    hrPage: 0,
    reikPage: 0,
  }); // Page indexes for navigation.
  const [currentPeriod, setCurrentPeriod] = useState(0);
  const location = useLocation();

  const handleError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here
    console.error(error, info);
  };

  useEffect(() => {
    // Check if current page is same as location
    if (location.pathname === "/") {
      setPages!((pages) => {
        return { ...pages, page: 0 };
      });
    }

    const getData = async () => {
      const tekjurGjold = await fetch(
        "https://rikisreikningurapi.azurewebsites.net/api/FJS/TekjurOgGjold",
        {
          method: "GET",
          headers: {
            accept: "text/plain",
            "X-Api-Key": api_key,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });

      const malefniData = await fetch(
        "https://rikisreikningurapi.azurewebsites.net/api/FJS/Data/malefni_tg",
        {
          method: "GET",
          headers: {
            accept: "text/plain",
            "X-Api-Key": api_key,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });

      const currentData = await fetch(
        "https://rikisreikningurapi.azurewebsites.net/api/FJS/NuverandiTimabil",
        {
          method: "GET",
          headers: {
            accept: "text/plain",
            "X-Api-Key": api_key,
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
      try {
        const revenue = mapData(tekjurGjold.tekjur_gjold, "Tekjur");
        const costs = mapData(tekjurGjold.tekjur_gjold, "Gjöld");
        const afkoma = mapData(tekjurGjold.afkoma, "Afkoma");
        const malefniJson = JSON.parse(malefniData[0]);

        const malefniTop = mapMalefni(malefniJson.malefni_tg);

        const dataResponse = {
          graph1: revenue,
          graph2: costs,
          graph3: afkoma,
          graph4: malefniTop,
        };

        setGraphData(dataResponse);
        setCurrentPeriod(parseInt(currentData.timabil));
        setLoading(false);
      } catch (error) {
        console.error(error);
        throw error;
      }
    };
    try {
      getData();
    } catch (error) {
      console.error(error);
      throw error;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageContext.Provider value={{ pages, setPages, drawer }}>
        <Layout>
          {loading ? (
            <Loader />
          ) : (
            <ErrorBoundary FallbackComponent={ErrorPage} onError={handleError}>
              <SideDrawer />
              <Switch>
                <Route exact path="/">
                  <Home data={graphData} current={currentPeriod} />
                </Route>
                <Route path="/fjarhagur">
                  <ReportsPage />
                </Route>
                <Route path="/mannaudur">
                  <HR />
                </Route>

                <Route path="/reikningur">
                  <Reikningur />
                </Route>

                <Route path="/utgefin-gogn">
                  <Efni />
                </Route>
                <Route path="/um-siduna">
                  <About />
                </Route>
                <Route path="/villa">
                  <ErrorPage />
                </Route>
              </Switch>
            </ErrorBoundary>
          )}
        </Layout>
      </PageContext.Provider>
    </>
  );
};
