import React from "react";
import { Sector } from "recharts";

export const formatText = (texti) => {
  let formatted = texti[0].toUpperCase() + texti.substr(1);
  if (formatted.includes("Tilfærslur")) {
    formatted = formatted.substr(0, 10) + "\n" + formatted.substr(10);
  }
  return formatted;
};

export const mapData = (data, type: string) => {
  const results: any = [];

  data.forEach((item) => {
    if (item.tegund === type) {
      // Matches the type of the item to return only revenues or only costs.
      let entry = {
        year: item.timabil_ar,
        name: formatText(item.texti),
        value: Math.abs(item.samtals),
      };
      results.push(entry);
    } else if (type === "Afkoma") {
      let entry = {
        year: item.ar.toString(),
        Tekjur: item.tekjur,
        Gjöld: item.gjold,
        Afkoma: item.afkoma,
      };
      results.push(entry);
    }
  });

  results.sort((a, b) => (a.year > b.year ? 1 : -1));

  return results;
};

export const mapMalefni = (data) => {
  const year2015 = data.filter((item) => {
    return item.timabil_ar === 2015;
  });
  const year2016 = data.filter((item) => {
    return item.timabil_ar === 2016;
  });
  const year2017 = data.filter((item) => {
    return item.timabil_ar === 2017;
  });
  const year2018 = data.filter((item) => {
    return item.timabil_ar === 2018;
  });
  const year2019 = data.filter((item) => {
    return item.timabil_ar === 2019;
  });
  const year2020 = data.filter((item) => {
    return item.timabil_ar === 2020;
  });
  const year2021 = data.filter((item) => {
    return item.timabil_ar === 2021;
  });
  const year2022 = data.filter((item) => {
    return item.timabil_ar === 2022;
  });
  const year2023 = data.filter((item) => {
    return item.timabil_ar === 2023;
  });
  const year2024 = data.filter((item) => {
    return item.timabil_ar === 2024;
  });

  const results = [
    {
      year: 2015,
      data: year2015
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2016,
      data: year2016
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2017,
      data: year2017
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2018,
      data: year2018
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2019,
      data: year2019
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2020,
      data: year2020
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2021,
      data: year2021
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2022,
      data: year2022
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2023,
      data: year2023
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
    {
      year: 2024,
      data: year2024
        .map((item) => {
          return {
            Málefnaflokkur: item.malefnasvid_heiti,
            Kostnaður: Math.abs(item.samtals),
            category: item.tegund_l1_number,
          };
        })
        .filter((fil) => fil.Málefnaflokkur !== "Ótengt"),
    },
  ];
  return results;
};

// export const calcProfit = (rev, cost) => {
//   console.log("vvvvvvvvvvvvvvvvvvvvvvvvv", rev, cost);
//   let year = 2017;

//   let summa = 0;
//   const revenues: any = [];
//   for (let i = 0; i < rev.length; i++) {
//     if (rev[i].year < 2017) {
//       continue;
//     }
//     if (year === rev[i].year) summa += rev[i].value;
//     else {
//       console.log(i, year);

//       revenues.push({ year: year, value: summa });
//       year++;
//       summa = 0;
//     }

//     if (i === rev.length - 1) revenues.push({ year: year, value: summa });
//   }

//   summa = 0;
//   year = 2017;
//   const costs: any = [];

//   for (let j = 0; j < cost.length; j++) {
//     if (cost[j].year < 2017) {
//       continue;
//     }
//     if (year === cost[j].year) summa += cost[j].value;
//     else {
//       console.log(year);
//       costs.push({ year: year, value: summa });
//       year++;
//       summa = 0;
//     }

//     if (j === cost.length - 1) costs.push({ year: year, value: summa });
//   }

//   const results: any = [];

//   for (let k = 0; k < revenues.length; k++) {
//     results.push({
//       year: revenues[k].year,
//       Tekjur: revenues[k].value,
//       Gjöld: costs[k].value,
//       Afkoma: revenues[k].value - costs[k].value,
//     });
//   }
//   return results;
// };

export const dataLabel = (value) => {
  try {
    const val = parseInt(value);
    if (Math.abs(parseInt(value)) / 1000000000 > 0)
      return val < 0
        ? "-" +
            Math.round(Math.abs(parseInt(value)) / 1000000000).toString() +
            " ma."
        : "" +
            Math.round(Math.abs(parseInt(value)) / 1000000000).toString() +
            " ma.";
    else
      return val < 0
        ? "-" + Math.abs(parseInt(value)).toString() + " m."
        : "" + Math.abs(parseInt(value)).toString() + " m.";
  } catch {
    return value;
  }
};

export const formatLabel = (props) => {
  const { x, y, width, value } = props;
  return (
    <text
      x={x + width / 2}
      y={y - 10}
      fill="black"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {dataLabel(value)}
    </text>
  );
};

export const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";
  return (
    <g>
      <text
        fontSize="30px"
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={fill}
      >
        {dataLabel(Math.abs(payload.value))}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`m${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        fontSize="18px"
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${payload.name}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
        overflow="wrap"
      >
        {`(Hlutfall ${(
          parseInt(
            parseInt((percent * 1000).toFixed(1)).toLocaleString("de-DE")
          ) / 10
        ).toLocaleString("de-DE")}%)`}
      </text>
    </g>
  );
};
