import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { dataLabel } from "../../utils/charts";
import { getMaxYear } from "../../utils/utils";

interface TopicLegendProps {
  graphTitle?: string;
  year?: number;
  data: {
    Málefnaflokkur: string;
    Kostnaður: number;
    category: string;
  }[];
  setYear: React.Dispatch<React.SetStateAction<number>>;
}

const COLORS = ["#4E8ECC", "#60986E", "#F2C94C", "#B5B5B5", "#F18921"];

export const TopicLegend: React.FC<TopicLegendProps> = ({
  graphTitle,
  data,
  year,
  setYear,
}) => {
  const todaysYear = getMaxYear();
  // const todaysYear = new Date().getFullYear();
  return (
    <>
      <Flex
        direction={["column", "column", "column", "column", "column", "row"]}
        mb={4}
        w="100%"
        alignItems="center"
      >
        <Heading w={["100%", "100%", "100%", "70%"]} fontSize="4xl">
          Veldu ár
        </Heading>
        <VStack h="100%" w="100%" justifyContent="center">
          <Icon
            as={ChevronUpIcon}
            color={year === todaysYear ? "gray.100" : "black"}
            onClick={() => {
              year === todaysYear ? setYear(year) : setYear(year! + 1);
            }}
          />
          <Heading fontSize="4xl">{year}</Heading>
          <Icon
            as={ChevronDownIcon}
            color={year === 2017 ? "gray.100" : "black"}
            onClick={() => {
              year === 2017 ? setYear(year) : setYear(year! - 1);
            }}
          />
        </VStack>
      </Flex>
      <Box>
        <Box textAlign="left" borderBottom="1px solid #000000" mb={4} py={2}>
          <Heading fontSize="md">{graphTitle}</Heading>
        </Box>
        <VStack textAlign="left" w="100%">
          {data.map((entry, index) => {
            return (
              <HStack
                key={index}
                maxH={["90px", "90px", "50px"]}
                w="100%"
                align="left"
              >
                <Box>
                  <svg width="30" height="30" viewBox="0 0 100 100">
                    <circle
                      cx="60"
                      cy="50"
                      r="30"
                      fill={COLORS[index % COLORS.length]}
                    ></circle>
                  </svg>
                </Box>
                <VStack w={["100%"]} alignItems="baseline">
                  <Text fontSize={["md", "md", "xl"]}>
                    {entry.Málefnaflokkur}{" "}
                  </Text>

                  <Text
                    fontSize={["md", "md", "xl"]}
                    fontWeight="bold"
                    display={["block", "block", "none", "none"]}
                  >
                    {dataLabel(entry.Kostnaður)}
                  </Text>
                </VStack>
              </HStack>
            );
          })}
        </VStack>
      </Box>
    </>
  );
};
