import { Box, Heading, Text } from "@chakra-ui/react";
import React from "react";

interface StatCardLineProps {
  heading: string;
  value: string;
  color: string;
}

export const StatCardLine: React.FC<StatCardLineProps> = ({
  heading,
  value,
  color,
}) => {
  return (
    <>
      <Box
        width="100%"
        h={["100px", "60px", "100px"]}
        p={[2, 2, 6]}
        borderLeft={"8px solid " + color}
        textAlign="left"
        boxShadow="0px 10px 30px rgba(0, 0, 0, 0.08);"
        color="gray.600"
        mr={["5px", "10px", "20px"]}
      >
        <Text fontSize={["sm", "sm", "lg"]}>{heading}</Text>
        <Heading fontSize={["16px", "20px", "26px", "30px", "30px", "36px"]}>
          {value}
        </Heading>
      </Box>
    </>
  );
};
