import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import Lottie from "react-lottie";
import animationData from "../../data/loader.json";
// import test from "../../data/pinjump.json";

interface LoaderProps {
  error?: boolean;
}

export const Loader: React.FC<LoaderProps> = ({ error }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box my={12} height="50%">
      {error ? (
        <Heading>
          Það kom upp villa við að sækja gögnin á síðunni. Vinsamlegast reyndu
          að endurhlaða vefinn.
        </Heading>
      ) : (
        <Heading color="headers.blue">Hleð inn gögnum...</Heading>
      )}
      <Box my={8}>
        <Lottie options={defaultOptions} width={400} height={200} />
      </Box>
    </Box>
  );
};
