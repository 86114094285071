import { Box } from "@chakra-ui/react";
import React from "react";
import { HeadingCard } from "../ui/HeadingCard";

interface DataSectionProps {
  pageHeader: string;
  headerText: string[];
  headerAsset: string;
}

export const DataSection: React.FC<DataSectionProps> = ({
  pageHeader,
  headerText,
  headerAsset,
}) => {
  return (
    <>
      <Box align="center" w={["100%", "100%", "100%", "100%"]}>
        <Box
          my={[3, 3, 3, 3]}
          py={[3, 3, 3, 3]}
          maxW={["80%", "80%", "60%", "75%"]}
          textAlign="left"
        >
          <HeadingCard heading={pageHeader} panel={headerText} size="4xl" />
        </Box>
      </Box>
    </>
  );
};
