import { Box, Heading, HStack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { PageContext } from "../../data/PageContext";

interface ExpandedDrawerButtonProps {
  type: string;
  heading: string;
  subheading?: string;
}

export const ExpandDrawer: React.FC<ExpandedDrawerButtonProps> = ({
  type,
  heading,
  subheading,
}) => {
  const [toggled, setToggled] = useState(false);
  const { drawer } = useContext(PageContext);

  const handleOpen = () => {
    drawer!.onOpen();
    setToggled(!toggled);
  };
  return (
    <>
      <Box
        w="100%"
        maxW="500px"
        boxShadow="0px 30px 60px rgba(0, 0, 0, 0.15)"
        border="1px solid #4E8ECC"
        p="2px"
      >
        <HStack
          w="100%"
          maxW="500px"
          boxShadow="0px 30px 60px rgba(0, 0, 0, 0.15)"
          borderLeft="5px solid #ffffff"
          _hover={{ borderLeft: "5px solid #4E8ECC" }}
          _active={{ borderLeft: "5px solid #4E8ECC" }}
          _focus={{ border: "1px solid #4E8ECC" }}
          _disabled={{ bgColor: "#4E8ECC", color: "white" }}
        >
          <Box
            as="button"
            w="100%"
            h="50px"
            textAlign="center"
            color="headers.gray"
            onClick={handleOpen}
          >
            <Heading size="md" w="100%">
              Skýringar
            </Heading>
          </Box>
        </HStack>
      </Box>
    </>
  );
};
