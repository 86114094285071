import { Button, Text } from "@chakra-ui/react";
import React from "react";
import { BiDownload } from "react-icons/bi";
import { api_key } from "../../configs/config";

interface DownloadExcelProps {
  url?: string;
}

export const DownloadExcel: React.FC<DownloadExcelProps> = ({ url }) => {
  const handleClick = () => {
    fetch(
      "https://rikisreikningurapi.azurewebsites.net/api/Files/Rikisreikningur/Forsidugogn.xlsx",
      {
        method: "GET",
        headers: {
          accept: "text/plain",
          "X-Api-Key": api_key, // TODO: Move to env variable.
        },
      }
    )
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = "Forsidugogn.xlsx";
          a.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Button
        w="200px"
        colorScheme="green"
        leftIcon={<BiDownload />}
        onClick={handleClick}
        variant="outline"
      >
        <Text fontSize="md" display="inline" mr={4}>
          Sækja gögn í Excel
        </Text>
      </Button>
    </>
  );
};
